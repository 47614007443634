import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'
import { useReactToPrint } from 'react-to-print';


export default function CarteirinhaDigital() {


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idFiliacao = queryParams.get('fl');
  const idFederacao = queryParams.get('fd');
  const mediaQuery = window.matchMedia('(max-width: 650px)');

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const refCarteirinha = useRef(null);

  const [federacao, setFederacao] = useState({})
  const [filiacao, setFiliacao] = useState({});
  const [usuario, setUsuario] = useState({})
  const [usuarioFiliacao, setUsuarioFiliacao] = useState({})
  const [equipe, setEquipe] = useState('')
  const [ultimoPagamento, setUltimoPagamento] = useState({})


  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            setUsuario(doc.data())

            if (doc.data().usuario === 2) {
              setUsuarioFiliacao(doc.data())

              // Carregar equipe
              if (doc.data().equipe) {

                servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                  setEquipe(e.data().equipe ? e.data().equipe : '')
                })

              }

              // carregar filiação
              const docRef = await servicos.filiacoes.doc(idFiliacao).get();
              if (docRef.exists) {
                const data = docRef.data();

                setFiliacao(data);

              }


            }

            if (doc.data().usuario === 3) {

              // carregar filiação
              const docRef = await servicos.filiacoes.doc(idFiliacao).get();
              if (docRef.exists) {
                const dataFiliacao = docRef.data();

                setFiliacao(dataFiliacao);
                // Carregar Pagamento

                if (dataFiliacao.idResponsavel) {
                  // Carregar Dados do Usuário
                  const docRefAluno = await servicos.usuarios.doc(dataFiliacao.idResponsavel).collection('competidores').doc(dataFiliacao.idFiliado).get()


                  if (docRefAluno.exists) {
                    const dataAluno = docRefAluno.data()
                    setUsuarioFiliacao(dataAluno)
                    // Carregar equipe 
                    if (dataAluno.equipe) {

                      servicos.equipes.doc(dataAluno.equipe).get().then((e) => {
                        setEquipe(e.data().equipe ? e.data().equipe : '')
                      })

                    }
                  }


                } else {
                  setUsuarioFiliacao(doc.data())
                  // setUsuarioSelecionado('eu')
                  if (doc.data().equipe) {

                    servicos.equipes.doc(doc.data().equipe).get().then((e) => {
                      setEquipe(e.data().equipe ? e.data().equipe : '')
                    })

                  }

                }

              }

            }

          }

        })

      } else {
        window.location = '/'
      }

    })

    // carregar Federação

    servicos.federacoes.doc(idFederacao).get().then(snap => {
      if (snap.exists) {
        setFederacao(snap.data());
      }
    })
  }, [])

  useEffect(() => {
    if (filiacao.id) {
      servicos.filiacoes.doc(filiacao.id).collection('pagamentos').get().then(snap => {

        const data = snap.docs.map(d => d.data())

        if (data.length > 0) {
          const _ultimoPagamento = data.sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
          setUltimoPagamento(_ultimoPagamento)
        }

      })

    }

  }, [filiacao])





  function converterData(data) {


    const _data = moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('YYYY-MM-DD')

    const [ano, mes, dia] = _data.split('-')
    const vencimento = federacao?.regrasFiliacao?.vencimentoAnuidade;
    const umAnoAposAprovacao = '1';
    const aoFimDoAno = '2';


    if (vencimento == umAnoAposAprovacao) {
      return moment([parseInt(ano) + 1, mes, dia].join('-')).format('DD/MM/YYYY');
    } else if (vencimento == aoFimDoAno) {
      return `31/12/${ano}`;
    }

  }




  const handlePrint = useReactToPrint({
    content: () => refCarteirinha.current
  });


  function backgroundCarteirinha() {
    if (federacao?.carteirinha?.estilizacaoFundo && federacao?.carteirinha?.estilizacaoFundo !== '1') {
      if (federacao?.carteirinha?.estilizacaoFundo === '2') {
        return federacao?.carteirinha?.corSolida
      }
      if (federacao?.carteirinha?.estilizacaoFundo === '3') {
        return `linear-gradient(to right, ${federacao?.carteirinha?.corGradiente1}, ${federacao?.carteirinha?.corGradiente2})`
      }
      if (federacao?.carteirinha?.estilizacaoFundo === '4') {
        return `url('${federacao?.carteirinha?.urlImagemFundo}') no-repeat center/cover`
      }
    }
  }



  return (
    <div className="body-wrapper blank-loder">

      <div style={{ display: mediaQuery.matches ? 'none' : 'block' }}>
        <Header />
      </div>

      {/* <Header /> */}


      <div style={{ display: mediaQuery.matches ? 'none' : 'block' }}>
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Carteirinha</h1>
            </div>
            <div class="headline__description">
              <span>Visualize os detalhes da sua filiação</span>
            </div>

          </div>

        </div>
      </div>
      </div>

      
      <div ref={refCarteirinha} class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  {filiacao.id && (
                    <div class="wpb_text_column wpb_content_element ">
                      <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                      </div>

                      <div
                        // ref={refCarteirinha}
                        style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px', width: '650px', margin: '0 auto', background: backgroundCarteirinha() }}>

                        <div style={{ height: '300px' }}>



                          <h6 style={{ position: 'relative', top: `${35 + federacao?.carteirinha?.deslYTitulo}px`, left: `${(17 + federacao?.carteirinha?.deslXTitulo)}px`, fontSize: `${federacao?.carteirinha?.fonteTitulo}px`, textAlign: 'center', color: federacao?.carteirinha?.corLetra }}>{federacao?.carteirinha?.titulo}</h6>


                          {!federacao?.carteirinha?.exibirMiniLogo && (
                            <div style={{ position: 'relative', top: '-15px', left: '13px', width: '70px', height: '70px' }}></div>
                          )}

                          {federacao?.carteirinha?.exibirMiniLogo && (
                            <div style={{ position: 'relative', top: '-15px', left: '13px' }}>
                              <div class="wpb_single_image wpb_content_element vc_align_left">
                                <figure class="wpb_wrapper vc_figure">
                                  <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey" style={{ border: 'unset', borderRadius: '5px', boxShadow: 'unset' }}>
                                    <img
                                      style={{ width: '70px', height: '70px' }}
                                      src={federacao?.carteirinha?.urlMiniLogo}
                                      class="vc_single_image-img attachment-full" alt=""
                                      srcset={federacao?.carteirinha?.urlMiniLogo}
                                    /></div>
                                </figure>

                              </div>
                            </div>
                          )}


                          <h6 style={{ position: 'relative', top: '12px', left: '520px', color: federacao?.carteirinha?.corLetra }}>Nº {filiacao.numeroFiliacao.toString().padStart(4, '0')}</h6>

                          <div style={{ position: 'relative', top: '15px', left: '477px' }}>
                            <div class="wpb_single_image wpb_content_element vc_align_left">
                              <figure class="wpb_wrapper vc_figure">
                                <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey">
                                  <img
                                    style={{ width: '140px', height: '170px' }}
                                    src={filiacao.urlImagemFoto
                                    }
                                    class="vc_single_image-img attachment-full" alt=""
                                    srcset={filiacao.urlImagemFoto
                                    }
                                  /></div>
                              </figure>

                            </div>
                          </div>

                          <div class="wpb_column vc_column_container" style={{ position: 'relative', top: '-190px', left: '-21px', width: '595px' }}>

                            <div class="vc_column-inner " style={{ marginTop: '-5px' }}>
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">
                                    <div role="form" class="row " >
                                      <div style={{ position: 'relative', top: '0px', left: '0px', width: '308px' }}>
                                        <span style={{ color: federacao?.carteirinha?.corLetra, fontWeight: 'bold' }}>Nome</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={filiacao.nomeFiliado.substring(0, 40)}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5', maxWidth: 'unset', width: '460px' }} />

                                      </div>


                                      <div style={{ position: 'relative', top: '-64px', left: '321px', width: '140px', visibility: 'hidden' }}>
                                        <span style={{
                                          color: federacao?.carteirinha?.corLetra
                                          , fontWeight: 'bold'
                                        }}>CPF</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={filiacao.cpfFiliado}
                                          disabled
                                          placeholder="CPF *" style={{ background: '#F5F5F5' }} />

                                      </div>
                                      <div style={{ position: 'relative', top: '-52px', left: '0px', width: '223px' }}>
                                        <span style={{ color: federacao?.carteirinha?.corLetra, fontWeight: 'bold' }}>Data Nascimento</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={usuarioFiliacao.dataNascimento ? moment(usuarioFiliacao.dataNascimento).format('DD/MM/YYYY') : ''}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />

                                      </div>
                                      <div style={{ position: 'relative', top: '-117px', left: '237px', width: '223px' }}>
                                        <span style={{ color: federacao?.carteirinha?.corLetra, fontWeight: 'bold' }}>Sexo</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={usuarioFiliacao?.sexo ? usuarioFiliacao.sexo.toUpperCase() : ''}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />

                                      </div>
                                      <div style={{ position: 'relative', top: '-106px', left: '0px', width: '223px' }}>
                                        <span style={{ color: federacao?.carteirinha?.corLetra, fontWeight: 'bold' }}>Equipe</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={equipe}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />

                                      </div>
                                      <div style={{ position: 'relative', top: '-169px', left: '237px', width: '223px' }}>
                                        <span style={{ color: federacao?.carteirinha?.corLetra, fontWeight: 'bold' }}>Graduação</span>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={filiacao.graduacao ? filiacao.graduacao.substring(0, 22) : 'não informada'}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />

                                      </div>

                                      {filiacao.dtAprovado && (
                                        <div class="" style={{ position: 'relative', top: '-172px', left: '508px' }}>
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6 style={{ color: federacao?.carteirinha?.corLetra }} >Vencimento</h6>
                                            <p style={{ color: federacao?.carteirinha?.corLetra }}>{converterData(filiacao.dtAprovado)}</p>                                                    </span>
                                        </div>
                                      )}


                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                        </div>

                      </div>

                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', cursor: 'pointer', marginTop: '114px' }} class="package-style-1__btn vc_col-lg-4" onClick={handlePrint}>
                        <div class="btn_defoult btn_fill vc_col-lg-3" ><i class="fa  fa-file" aria-hidden="true" style={{ color: "#fff" }}></i> Imprimir</div>
                      </div>
                    </div>
                  )}

                  
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <a href={filiacao.id ? `/detalheFiliacao?f=${idFederacao}&fl=${filiacao.id}` : `/federacao/${idFederacao}`} style={{ background: "#17a2b8" }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-lg-2" ><i class="fa fa-arrow-left"></i> Voltar</a>
                  </div>

        </main>
      </div>
      <div style={{ visibility: mediaQuery.matches ? 'hidden' : 'visible' }}>
        <Footer />
      </div>



    </div>
  );
}
