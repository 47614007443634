import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import './Filiacao.css'
import RG_Frente_Verso from './Imagens/RG-Frente-verso.jpg'
import Foto_Documento from './Imagens/foto-documento.jpg'
import profile from '../componentes/Imagens/profile.jpg'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import ModalEquipes from '../componentes/ModalEquipes'
import { useUtilitarios } from '../hooks/useUtilitarios'
import InputMask from "react-input-mask";
import ReactHtmlParser from 'react-html-parser';



export default function Filiacao() {

  const { idFederacao } = useParams()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filiacaoExt = queryParams.get('fl');
  const comp = queryParams.get('comp');

  const [servicos] = useState({
    eventos: firebase.firestore().collection("eventos"),
    equipes: firebase.firestore().collection("equipes"),
    usuarios: firebase.firestore().collection("usuarios"),
    inscricoes: firebase.firestore().collection('inscricoes'),
    federacoes: firebase.firestore().collection('federacoes'),
    filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
    mail: firebase.firestore().collection('mail'),
    configuracoes: firebase.firestore().collection('configuracoes'),
    auth: firebase.auth()
  })

  const [federacao, setFederacao] = useState({})

  const [modalidade, setModalidade] = useState('')
  const [graduacoes, setGraduacoes] = useState([])
  const [faixasFederacao, setFaixasFederacao] = useState([])
  const [filiacao, setFiliacao] = useState({});

  console.log('filiacao: ', filiacao);


  const [statusFiliacao, setStatusFiliacao] = useState(1)

  const [usuario, setUsuario] = useState({})
  const [usuarioFiliacao, setUsuarioFiliacao] = useState({})


  // Dados do Filiado
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [cpf, setCpf] = useState('')
  const [sexo, setSexo] = useState('')
  const [dataNascimento, setDataNascimento] = useState('')

  const [dadosEquipe, setDadosEquipe] = useState({})
  const [professor, setProfessor] = useState({})

  const [professores, setProfessores] = useState([])
  const [inputProfessor, setInputProfessor] = useState('')
  const [graduacao, setGraduacao] = useState('')

  const [grausPreta, setGrausPreta] = useState([])
  const [grauPreta, setGrauPreta] = useState('')
  const [dataGraduacao, setDataGraduacao] = useState('')

  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null)
  // const [equipe, setEquipe] = useState('')

  // Endereço de Entrega da Carteirinha

  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [cep, setCep] = useState('');

  const UFs = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MS', 'MT', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
  ]

  const [competidores, setCompetidores] = useState([])

  const [fotoRg, setFotoRg] = useState('Nenhum arquivo selecionado');
  const [arquivoRg, setArquivoRg] = useState('');
  const refRgFile = useRef(null);
  const [uploadArquivoRg, setUploadArquivoRg] = useState('');

  const [fotoDocumento, setFotoDocumento] = useState('Nenhum arquivo selecionado');
  const [arquivoFoto, setArquivoFoto] = useState('');
  const refDocumentoFile = useRef(null);
  const [uploadArquivoFoto, setUploadArquivoFoto] = useState('');

  const [nomeArquivoCertificadoFaixaPreta, setNomeArquivoCertificadoFaixaPreta] = useState('Nenhum arquivo selecionado');
  const [arquivoCertificadoFaixaPreta, setArquivoCertificadoFaixaPreta] = useState('');
  const refCertificadoFaixaPretaFile = useRef(null);
  const [uploadArquivoCertificadoFaixaPreta, setUploadArquivoCertificadoFaixaPreta] = useState('');

  const [fotoComprovante, setFotoComprovante] = useState('Nenhum arquivo selecionado');
  const [arquivoComprovante, setArquivoComprovante] = useState('');
  const [uploadArquivoComprovante, setUploadArquivoComprovante] = useState('');
  const refComprovanteFile = useRef(null);
  const [pagamentoPendente, setPagamentoPendente] = useState({})
  const [ultimoPagamento, setUltimoPagamento] = useState({})

  const [pagamentosAprovados, setPagamentosAprovados] = useState([])
  const [concordoTermo, setConcordoTermo] = useState(false);
  const [opcaoEntregaSelecionado, setOpcaoEntregaSelecionado] = useState('')

  const [adquirirCarteirinhaFisica, setAdquirirCarteirinhaFisica] = useState('')
  const [adquirirCarteirinhaDigital, setAdquirirCarteirinhaDigital] = useState('')

  const [progresso, setProgresso] = useState('')
  const [loading, setLoading] = useState(false)
  const loadingRef = useRef(loading);
  useEffect(() => { loadingRef.current = loading; }, [loading]);

  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)

  const refRg = useRef(null);
  const [modalShow, setModalShow] = useState(false);

  const utilitarios = useUtilitarios()
  const mediaQuery = window.matchMedia('(max-width: 650px)');
  const history = useHistory()

  useEffect(() => {

    servicos.auth.onAuthStateChanged(async (user) => {
      let idUser = JSON.parse(localStorage.getItem('usuarioId'))

      if (user || idUser) {

        servicos.usuarios.doc(user?.uid ? user.uid : idUser).get().then(async (doc) => {
          if (doc.exists) {
            const _usuario = doc.data()
            setUsuario(_usuario)

            if (_usuario.usuario === 2) {
              setUsuarioFiliacao(_usuario)

              await utilitarios.atualizarStatusFiliacao(idFederacao, [_usuario])

            }

            if (_usuario.usuario === 3) {

              servicos.usuarios.doc(_usuario.id).collection('competidores').get().then(async snap => {
                if (snap.size > 0) {
                  const data = snap.docs.map(d => d.data())
                  const _competidores = data.map(comp => {
                    return { ...comp, responsavel: _usuario.id }
                  })
                  setCompetidores(_competidores)

                  const usuarios = [_usuario, ..._competidores]
                  console.log('usuarios: ', usuarios);

                  await utilitarios.atualizarStatusFiliacao(idFederacao, usuarios)

                }
              })
            }

          }

        })

      } else {
        window.location = '/'
      }

    })

    // carregar Federação, Modalidade e Graduações

    servicos.federacoes.doc(idFederacao).get().then(snap => {

      const _federacao = snap.data();
      setFederacao(_federacao);
      setGrausPreta(buscarGrausPreta(_federacao))

      if (_federacao?.regrasFiliacao?.controlarTempoMinimoFaixa) {
        if (_federacao?.faixas?.length > 0) {
          setFaixasFederacao(_federacao?.faixas)
        }
      }

      const idModalidade = _federacao.modalidade

      servicos.configuracoes.get().then(snap => {
        const idConfiguracoes = snap.docs.map(d => d.id)[0];

        servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').doc(idModalidade).get().then(snap => {
          setModalidade(snap.data().descricao ? snap.data().descricao : '')
        })

        servicos.configuracoes.doc(idConfiguracoes).collection('modalidades').doc(idModalidade).collection('graduacoes').get().then(snap => {
          if (snap.size > 0) {
            const data = snap.docs.map(d => d.data());
            setGraduacoes(data);
          }
        })
      })

    })



  }, [])

  useEffect(async () => {
    if (filiacaoExt) {

      const docRefFiliacao = await servicos.filiacoes.doc(filiacaoExt).get();
      if (docRefFiliacao.exists) {
        const dadosFiliacao = {
          _id: docRefFiliacao.id,
          ...docRefFiliacao.data()
        };
        setFiliacao(dadosFiliacao);
        setStatusFiliacao(dadosFiliacao.statusFiliacao)
        setGraduacao(dadosFiliacao?.graduacao && dadosFiliacao.statusFiliacao !== 4 ? dadosFiliacao.graduacao : '')
        setGrauPreta(dadosFiliacao?.grauPreta && dadosFiliacao.statusFiliacao !== 4 ? dadosFiliacao?.grauPreta : null)
        setDataGraduacao(dadosFiliacao.dtGraduacao && dadosFiliacao.statusFiliacao !== 4 ? moment(new firebase.firestore.Timestamp(dadosFiliacao.dtGraduacao.seconds, dadosFiliacao.dtGraduacao.nanoseconds).toDate()).format('YYYY-MM-DD') : '')
        setOpcaoEntregaSelecionado(dadosFiliacao?.opcaoEntregaSelecionado ? dadosFiliacao.opcaoEntregaSelecionado : '')
        setConcordoTermo(dadosFiliacao?.concordoTermo ? dadosFiliacao?.concordoTermo : '')
        setAdquirirCarteirinhaFisica(dadosFiliacao?.adquirirCarteirinhaFisica ? dadosFiliacao?.adquirirCarteirinhaFisica : '')
        setEndereco(dadosFiliacao?.enderecoEntregaCarteirinha?.endereco ? dadosFiliacao?.enderecoEntregaCarteirinha?.endereco : '')
        setNumero(dadosFiliacao?.enderecoEntregaCarteirinha?.numero ? dadosFiliacao?.enderecoEntregaCarteirinha?.numero : '')
        setComplemento(dadosFiliacao?.enderecoEntregaCarteirinha?.complemento ? dadosFiliacao?.enderecoEntregaCarteirinha?.complemento : '')
        setBairro(dadosFiliacao?.enderecoEntregaCarteirinha?.bairro ? dadosFiliacao?.enderecoEntregaCarteirinha?.bairro : '')
        setCidade(dadosFiliacao?.enderecoEntregaCarteirinha?.cidade ? dadosFiliacao?.enderecoEntregaCarteirinha?.cidade : '')
        setUf(dadosFiliacao?.enderecoEntregaCarteirinha?.uf ? dadosFiliacao?.enderecoEntregaCarteirinha?.uf : '')
        setCep(dadosFiliacao?.enderecoEntregaCarteirinha?.cep ? dadosFiliacao?.enderecoEntregaCarteirinha?.cep : '')
        setAdquirirCarteirinhaDigital(dadosFiliacao?.adquirirCarteirinhaDigital ? dadosFiliacao?.adquirirCarteirinhaDigital : '')

        if (dadosFiliacao.idResponsavel) {
          // Carregar Dados do Usuário
          const docRefAluno = await servicos.usuarios.doc(dadosFiliacao.idResponsavel).collection('competidores').doc(dadosFiliacao.idFiliado).get()

          if (docRefAluno.exists) {
            const dataAluno = { ...docRefAluno.data(), responsavel: dadosFiliacao.idResponsavel }
            setUsuarioFiliacao(dataAluno)

            const index = competidores.findIndex(comp => comp.id === dataAluno.id)
            setUsuarioSelecionado(index)

          }

        } else {
          setUsuarioFiliacao(usuario)
          setUsuarioSelecionado('eu')

        }
      }

    }
  }, [filiacaoExt, usuario, competidores])


  useEffect(async () => {
    if (comp) {
      setUsuarioSelecionado(comp)
      if (comp === 'eu') {
        setUsuarioFiliacao(usuario)
      } else {
        if (competidores.length > 0) {
          setUsuarioFiliacao(competidores[comp])
        }
      }
    }
  }, [competidores])


  useEffect(() => {

    carregarPagamentos();

  }, [filiacao])

  useEffect(() => {

    if (usuario?.id) {
      if (usuarioFiliacao?.id) {
        excluirFiliacaoCorrompida()
      }
    }

  }, [filiacao, usuario, usuarioFiliacao])

  function carregarPagamentos() {
    if (filiacao.id) {
      servicos.filiacoes.doc(filiacao.id).collection('pagamentos').get().then(snap => {
        const data = snap.docs.map(d => d.data())
        if (data.some(pgto => pgto.status !== 2)) {
          const _pagamento = data.find(pgto => pgto.status !== 2)
          setPagamentoPendente(_pagamento)
          setFotoComprovante(_pagamento.nomeImagemComprovantePgto)
        }
        if (data.some(pgto => pgto.status == 2)) {
          const _pagamentos = data.filter(pgto => pgto.status == 2)
          setPagamentosAprovados(_pagamentos)
        }

        if (data.length > 0) {
          const _ultimoPagamento = data.sort((a, b) => b.dtAnexo - a.dtAnexo)[0]
          setUltimoPagamento(_ultimoPagamento)
        }

      })

    } else {
      setPagamentoPendente({})
      setFotoComprovante('Nenhum arquivo selecionado')
    }
  }

  async function excluirFiliacaoCorrompida() {

    if (Object.keys(filiacao).length > 0) {
      if (!filiacao.id) {

        filiacao.id = filiacao._id;

        const dadosExclusao = {
          idFederacao: idFederacao,
          filiacao: filiacao,
          usuarioFiliacao: usuarioFiliacao,
          usuario: usuario,
          pagamentoPendente: null,
          pagamentosAprovados: null
        }


        utilitarios.excluirFiliacao(dadosExclusao).then(() => {

          const msg = "Não foi possível concluir sua filiação. Verifique sua conexão de internet e tente novamente!"

          return Swal.fire('Atenção', msg, 'warning');

        }).then(() => {
          window.location.reload();
        }).catch(error => {
          console.log('erro: ', error.message);
        })
      }


    }
  }


  useEffect(async () => {

    if (usuarioFiliacao.id) {
      setNome(usuarioFiliacao.nome ? usuarioFiliacao.nome : '')
      setSobrenome(usuarioFiliacao.sobrenome ? usuarioFiliacao.sobrenome : '')
      setCpf(usuarioFiliacao.cpf ? usuarioFiliacao.cpf : '')
      setSexo(usuarioFiliacao.sexo ? usuarioFiliacao.sexo : '')
      setDataNascimento(usuarioFiliacao.dataNascimento ? moment(usuarioFiliacao.dataNascimento).format('YYYY-MM-DD') : '')

      // Carregar equipe e professor
      if (usuarioFiliacao.equipe) {

        servicos.equipes.doc(usuarioFiliacao.equipe).get().then((snap) => {
          if (snap.exists) {
            setDadosEquipe(snap.data())
          }
        })

        if (usuarioFiliacao.professor) {
          servicos.equipes.doc(usuarioFiliacao.equipe).collection('professores').doc(usuarioFiliacao.professor).get().then((snap) => {
            if (snap.exists) {
              setProfessor(snap.data())
            }
          })
        }

        servicos.equipes.doc(usuarioFiliacao.equipe).collection('professores').get().then((snap) => {
          const data = snap.docs.map((d) => d.data())
          setProfessores(data)
        })

      }



      // carregar filiação
      if (usuarioFiliacao?.filiacoes?.length > 0) {
        if (usuarioFiliacao?.filiacoes.includes(idFederacao)) {

          const docRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').where('idFiliado', '==', usuarioFiliacao.id).where('tipoUsuario', '==', usuarioFiliacao.tipoUsuario).get();

          if (!docRef.empty) {

            const _filiacao = docRef.docs.map(d => {
              return {
                _id: d.id,
                ...d.data()
              }
            })[0];

            setConcordoTermo(_filiacao.concordoTermo ? _filiacao.concordoTermo : '')
            setStatusFiliacao(_filiacao.statusFiliacao ? _filiacao.statusFiliacao : '')
            setGraduacao((_filiacao.graduacao && _filiacao.statusFiliacao !== 4) ? _filiacao.graduacao : '')
            setGrauPreta(_filiacao?.grauPreta && _filiacao.statusFiliacao !== 4 ? _filiacao?.grauPreta : null)
            setDataGraduacao(_filiacao.dtGraduacao && _filiacao.statusFiliacao !== 4 ? moment(new firebase.firestore.Timestamp(_filiacao.dtGraduacao.seconds, _filiacao.dtGraduacao.nanoseconds).toDate()).format('YYYY-MM-DD') : '')
            setFiliacao(_filiacao);
            setFotoRg(_filiacao.nomeImagemRg ? _filiacao.nomeImagemRg : '')
            setFotoDocumento(_filiacao.nomeImagemFoto ? _filiacao.nomeImagemFoto : '')
            setNomeArquivoCertificadoFaixaPreta(_filiacao.nomeArquivoCertificadoFaixaPreta ? _filiacao?.nomeArquivoCertificadoFaixaPreta : '')
            setOpcaoEntregaSelecionado(_filiacao.opcaoEntregaSelecionado ? _filiacao.opcaoEntregaSelecionado : '')
            setEndereco(_filiacao?.enderecoEntregaCarteirinha?.endereco ? _filiacao?.enderecoEntregaCarteirinha?.endereco : '')
            setNumero(_filiacao?.enderecoEntregaCarteirinha?.numero ? _filiacao?.enderecoEntregaCarteirinha?.numero : '')
            setComplemento(_filiacao?.enderecoEntregaCarteirinha?.complemento ? _filiacao?.enderecoEntregaCarteirinha?.complemento : '')
            setBairro(_filiacao?.enderecoEntregaCarteirinha?.bairro ? _filiacao?.enderecoEntregaCarteirinha?.bairro : '')
            setCidade(_filiacao?.enderecoEntregaCarteirinha?.cidade ? _filiacao?.enderecoEntregaCarteirinha?.cidade : '')
            setUf(_filiacao?.enderecoEntregaCarteirinha?.uf ? _filiacao?.enderecoEntregaCarteirinha?.uf : '')
            setCep(_filiacao?.enderecoEntregaCarteirinha?.cep ? _filiacao?.enderecoEntregaCarteirinha?.cep : '')

          }



        }
      }

    }

  }, [usuarioFiliacao])

  const handleFotoRg = (event) => {
    event.stopPropagation()
    setArquivoRg(event.target.files[0])
    if (uploadArquivoRg) {
      URL.revokeObjectURL(uploadArquivoRg)
    }
    setUploadArquivoRg(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoRg(file ? file.name : 'Nenhum arquivo selecionado');
  };

  const handleFotoDocumento = (event) => {
    console.log('Chamou handleFotoDocumento(');
    event.stopPropagation()
    setArquivoFoto(event.target.files[0])
    if (uploadArquivoFoto) {
      URL.revokeObjectURL(uploadArquivoFoto)
    }
    setUploadArquivoFoto(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoDocumento(file ? file.name : 'Nenhum arquivo selecionado');

  };

  const handleCertificadoFaixaPreta = (event) => {
    console.log('Chamou handleCertificadoFaixaPreta()');
    event.stopPropagation()
    setArquivoCertificadoFaixaPreta(event.target.files[0])
    if (uploadArquivoCertificadoFaixaPreta) {
      URL.revokeObjectURL(uploadArquivoCertificadoFaixaPreta)
    }
    setUploadArquivoCertificadoFaixaPreta(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    setNomeArquivoCertificadoFaixaPreta(file ? file.name : 'Nenhum arquivo selecionado');

  };


  const handleFotoComprovante = (event) => {
    event.stopPropagation()
    setArquivoComprovante(event.target.files[0])
    if (uploadArquivoComprovante) {
      URL.revokeObjectURL(uploadArquivoComprovante)
    }
    setUploadArquivoComprovante(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
    const file = event.target.files[0];
    console.log('file: ', file);
    setFotoComprovante(file ? file.name : 'Nenhum arquivo selecionado');

  };

  const buscarGrausPreta = (_federacao) => {
    if (_federacao?.faixas?.length > 0) {
      if (_federacao?.faixas?.some(fx => fx?.descricao === 'PRETA')) {
        const faixaPreta = _federacao?.faixas?.find(fx => fx?.descricao === 'PRETA')
        if (faixaPreta?.graus?.length > 0) {
          return faixaPreta?.graus
        }
      }
    }
    return []
  }

  function exibirGrausPreta() {
    if (federacao?.regrasFiliacao?.controlarTempoMinimoFaixa) {
      if (graduacao === 'PRETA' && grausPreta.length > 0) {
        return true;
      }
    }
    return false;
  }

  const handleCompetidor = async (event) => {
    event.persist();
    setUsuarioSelecionado(event.target.value)
    setLoading(true)
    setUsuarioFiliacao({})
    setFiliacao({})
    setNome('')
    setCpf('')
    setSexo('')
    setDataNascimento('')
    setDadosEquipe({})
    setProfessor({})
    setFotoRg('Nenhum arquivo selecionado')
    setArquivoRg('')
    setUploadArquivoRg('')
    setFotoDocumento('Nenhum arquivo selecionado')
    setArquivoFoto('')
    setFotoComprovante('Nenhum arquivo selecionado')
    setUploadArquivoFoto('')
    setArquivoComprovante('')
    setUploadArquivoComprovante('')
    setProfessor({})
    setPagamentosAprovados([])
    setPagamentoPendente([])
    setConcordoTermo(false)
    setGraduacao('')
    setDataGraduacao('')
    setOpcaoEntregaSelecionado('')

    if (event.target.value !== "") {
      if (event.target.value === "eu") {
        setUsuarioFiliacao(usuario);

      } else {

        const competidor = competidores[event.target.value]
        setUsuarioFiliacao(competidor)
        setLoading(false)

      }
    }
  }


  function mensagemAtencao(msg) {
    Swal.fire('Atenção', msg, 'warning')
  }
  function mensagemAlerta(msg) {
    return Swal.fire('Alerta', msg, 'warning')
  }
  function mensagemReprovado(msg) {
    Swal.fire('Documento Reprovado', msg, 'warning')
  }

  function mensagemSucesso(msg, idInscricao) {
    Swal.fire('Sucesso', msg, 'success').then(() => {
      // if (itemVoucher.id && itemVoucher.tipo === 'cortesia') {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/evento/' + evento.id)
      // } else if ((valorAbsoluto + valorCategoria) === 0) {
      //   window.location = window.location.href.replace('/inscricao/' + id, '/minhaInscricao/' + id)
      // } else {
      //   window.location.href = `/pagamento?e=${id}&i=${idInscricao}`
      // }

    })
  }

  async function mensagemConfirmacao(titulo, texto) {

    const result = await Swal.fire({
      title: titulo,
      text: texto,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#6c757d',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (result.isConfirmed) {
      return true;
    } else if (result.isDismissed || result.isDenied) {
      return false;
    }
  }

  function mensagemLoading(msg) {
    Swal.fire({
      title: 'Aguarde',
      html: msg,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    })
  }
  function mensagemErro(msg) {
    Swal.fire('Erro', msg, 'error')
  }




  async function cadastraImagem(idFiliacao, arquivo, _imagem, idPagamento) {

    return new Promise((resolve, reject) => {

      let caminho = (arquivo !== 'comprovante') ?
        `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${arquivo}` :
        `federacoes/${idFederacao}/filiacoes/${idFiliacao}/pagamentos/${idPagamento}`

      var uploadTask = firebase.storage().ref().child(caminho).put(_imagem, { contentType: _imagem.type });

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgresso(parseInt(progress) + '%')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('uploadImagem is paused');
            setLoadingImage(false)
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log(`Salvando ${arquivo}`);
            break;
          default:
            console.log('Passou')
        }
      }, (error) => {
        console.error(`Error during upload: ${error.message}`);
        reject(error);
      }, async () => {


        try {


          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

          let dados = {}

          if (arquivo === 'rg') {

            dados = {
              ...dados,
              urlImagemRg: downloadURL,
              statusImagemRg: 1,
              nomeImagemRg: _imagem.name,
              dtAnexoRg: firebase.firestore.Timestamp.fromDate(new Date())
            }

            if (filiacao.statusImagemRg === 3) {
              dados = { ...dados, observacaoRg: '' }
            }

            // await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update(dados)

          }

          if (arquivo === 'foto') {
            dados = {
              ...dados,
              urlImagemFoto: downloadURL,
              statusImagemFoto: 1,
              nomeImagemFoto: _imagem.name,
              dtAnexoFoto: firebase.firestore.Timestamp.fromDate(new Date())
            }
            if (filiacao.statusImagemFoto === 3) {
              dados = { ...dados, observacaoFoto: '' }
            }

            // await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update(dados)

          }

          if (arquivo === 'certificadoFaixaPreta') {
            dados = {
              ...dados,
              urlImagemCertificadoFaixaPreta: downloadURL,
              statusImagemCertificadoFaixaPreta: 1,
              nomeArquivoCertificadoFaixaPreta: _imagem.name,
              dtAnexoCertificadoFaixaPreta: firebase.firestore.Timestamp.fromDate(new Date())
            }
            if (filiacao?.statusImagemCertificadoFaixaPreta === 3) {
              dados = { ...dados, observacaoCertificadoFaixaPreta: '' }
            }

            // await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update(dados)

          }

          if (arquivo === 'comprovante') {

            dados = {
              urlComprovantePgto: downloadURL,
              nomeImagemComprovantePgto: _imagem.name,
              dtAnexo: firebase.firestore.Timestamp.fromDate(new Date()),
              status: 1
            }

            if (pagamentoPendente.status === 3) {
              dados = { ...dados, observacaoComprovante: '' }
            }

            // await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').doc(idPagamento).update(dados)

          }

          resolve(dados);
        } catch (error) {

          console.log(`Erro upload ${arquivo}: `, error);

          reject(error);
        }

      });

    });

  }

  function validacao() {

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 100KB limit
    // const MAX_FILE_SIZE = 100 * 1024; // 100KB limit

    // Validação da Filiação
    if (!usuarioFiliacao.id) {
      mensagemAlerta('Por favor, selecione um usuário para a filiação');
      return false;
    }
    if (!graduacao) {
      mensagemAlerta('Por favor, selecione uma Graduação');
      return false;
    }
    if (!dataGraduacao) {
      mensagemAlerta('Por favor, informe a DATA de sua Graduação');
      return false;
    }


    if (dataGraduacao.includes('/')) {
      const verificarDataValida = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()); // Check if the date is valid
      }

      const dataGraduacaoValida = verificarDataValida(converterMascaraParaData(dataGraduacao))

      if (!dataGraduacaoValida) {
        mensagemAlerta(`Por favor, informe uma Data Válida para ${graduacao === 'BRANCA' ? 'o Início dos Treinos' : 'a sua Graduação'}`);
        return false;
      }
    }



    if (federacao?.regrasFiliacao?.controlarTempoMinimoFaixa) {
      // verificar a o tempo mínimo da faixa atual

      if (filiacao?.graduacao) {
        const fxFederacao = faixasFederacao.find(fx => fx.descricao === filiacao?.graduacao)



        if (fxFederacao?.descricao !== 'PRETA') {
          const tempoMinimo = fxFederacao?.tempoMinimo;

          if (filiacao?.statusFiliacao === 4) {
            if (filiacao?.graduacao !== graduacao) {
              const tempoDecorrido = moment().diff(moment(converterData(filiacao?.dtGraduacao), 'DD-MM-YYYY HH:mm'), 'years')
              console.log('tempoMinimo: ', tempoMinimo);
              console.log('tempoDecorrido: ', tempoDecorrido);


              if (tempoDecorrido < parseInt(tempoMinimo)) {
                mensagemAlerta(`O tempo mínimo de permanencia na faixa ${fxFederacao?.descricao} estipulado pela Federação (${fxFederacao?.tempoMinimo} anos) ainda não foi atingido. Não será possível prosseguir com a filiação selecionanda a faixa ${graduacao}`)
                return false;
              }
            }

          }

        }

        if (fxFederacao?.descricao === 'PRETA') {
          if (grausPreta.length > 0 && !grauPreta) {
            mensagemAlerta('Por favor, informe o GRAU de faixa preta que você possui.')
            return false;
          }

          if (filiacao?.statusFiliacao === 4) {
            if (filiacao?.grauPreta) {
              if (filiacao?.grauPreta != grauPreta) {
                const tempoDecorrido = moment().diff(moment(converterData(filiacao?.dtGraduacao), 'DD-MM-YYYY HH:mm'), 'years');

                const grausPreta = fxFederacao?.graus;
                const dadosGrauPreta = grausPreta.find(g => g.grau == filiacao.grauPreta);
                const tempoMinimo = dadosGrauPreta?.tempoMinimo;


                if (tempoDecorrido < parseInt(tempoMinimo)) {
                  mensagemAlerta(`O tempo mínimo de permanencia no grau ${filiacao?.grauPreta} da faixa PRETA estipulado pela Federação (${dadosGrauPreta?.tempoMinimo} anos) ainda não foi atingido. Não será possível prosseguir com a filiação selecionado o grau ${grauPreta}`)
                  return false;
                }
              }
            }

          }
        }


      }

    }

    if (federacao?.carteirinha?.carteirinhaFisica == '2' && !adquirirCarteirinhaFisica) {
      mensagemAlerta('Por favor, informe se deseja adquirir a carteirnha de filiado (Física) .');
      return false;
    }
    if (federacao?.carteirinha?.carteirinhaDigital == '2' && !adquirirCarteirinhaDigital) {
      mensagemAlerta('Por favor, informe se deseja adquirir a carteirnha de filiado (Digital) .');
      return false;
    }


    if (incluirCarteirinhaFisica()) {

      if (federacao?.carteirinha?.opcaoEntrega == '3' && !opcaoEntregaSelecionado) {
        mensagemAlerta('Por favor, selecione uma opção para entrega da carteirnha.');
        return false;
      }
      const opcional = '3';
      const envioAoFiliado = '2'
      if (
        federacao?.carteirinha?.opcaoEntrega == envioAoFiliado ||
        (federacao?.carteirinha?.opcaoEntrega == opcional && opcaoEntregaSelecionado == envioAoFiliado)) {

        if (!endereco) {
          mensagemAlerta('Por favor, informe o endereço para envio de carteirinha ao filiado.');
          return false;
        }
        if (!numero) {
          mensagemAlerta('Por favor, informe o número residencial para envio de carteirinha ao filiado.');
          return false;
        }

        if (!cidade) {
          mensagemAlerta('Por favor, informe a cidade para envio de carteirinha ao filiado.');
          return false;
        }

        if (!uf) {
          mensagemAlerta('Por favor, informe a uf para envio de carteirinha ao filiado.');
          return false;
        }
        if (!cep) {
          mensagemAlerta('Por favor, informe a cep para envio de carteirinha ao filiado.');
          return false;
        }

      }

    }

    if (!filiacao.id) {
      if (!uploadArquivoRg) {

        mensagemAlerta('Por favor, anexe uma foto do seu RG (Frente e Verso)');
        return false;

      } else {

        if (arquivoRg.size > MAX_FILE_SIZE) {
          mensagemAlerta('O RG anexado excede o tamanho máximo permitido (5MB)');
          return false;
        }

      }

      if (!uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma foto do filiado');
        return false;
      } else {

        if (arquivoFoto.size > MAX_FILE_SIZE) {
          mensagemAlerta('A Foto 3x4 anexada excede o tamanho máximo permitido (5MB)');
          return false;
        }

      }

      if (exibirInputDiplomaFaixaPreta()) {
        if (!uploadArquivoCertificadoFaixaPreta) {
          mensagemAlerta('Por favor, anexe uma certificado de graduação na Faixa Preta');
          return false;
        }
      } else {

        if (arquivoFoto.size > MAX_FILE_SIZE) {
          mensagemAlerta('Certificado de Faixa Preta anexado excede o tamanho máximo permitido (5MB)');
          return false;
        }

      }

      // if (valorTotal() > 0) {
      //   if (!uploadArquivoComprovante) {
      //     mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento para renovar sua filiação.');
      //     return false;
      //   }
      // }

    } else if (filiacao.id) {

      if (filiacao.statusImagemRg === 3 && !uploadArquivoRg) {
        mensagemAlerta('Por favor, anexe uma nova foto do RG/CNH');
        return false;
      }
      if (filiacao.statusImagemFoto === 3 && !uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma nova foto do Filiado');
        return false;
      }
      if (filiacao?.statusImagemCertificadoFaixaPreta === 3 && !uploadArquivoCertificadoFaixaPreta) {
        mensagemAlerta('Por favor, anexe uma novo certificado de graduação na Faixa Preta');
        return false;
      }
      // if (pagamentoPendente?.status === 3 && !uploadArquivoComprovante) {
      //   mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento');
      //   return false;
      // }
      if (filiacao.statusImagemRg === 4 && !uploadArquivoRg) {
        mensagemAlerta('Por favor, anexe uma nova foto do RG/CNH para renovar sua filiação.');
        return false;
      }
      if (filiacao.statusImagemFoto === 4 && !uploadArquivoFoto) {
        mensagemAlerta('Por favor, anexe uma nova foto do Filiado para renovar sua filiação.');
        return false;
      }
      if (filiacao?.statusImagemCertificadoFaixaPreta === 4 && !uploadArquivoCertificadoFaixaPreta) {
        mensagemAlerta('Por favor, anexe uma novo certificado de graduação na Faixa Preta');
        return false;
      }
      if (filiacao.statusFiliacao === 4) {
        // if (valorTotal() > 0) {
        //   if (!uploadArquivoComprovante) {
        //     mensagemAlerta('Por favor, anexe uma nova foto do comprovante de pagamento para renovar sua filiação.');
        //     return false;
        //   }
        // }

      }

    }

    if (!concordoTermo) {
      mensagemAlerta('Para concluir, leia e confirme os termos da filiação.');
      return false;
    }

    // Validar Alterações no Perfil

    if (!nome) {
      mensagemAlerta('Por favor, informe o NOME do usuário');
      return false;
    }
    if (!sobrenome) {
      mensagemAlerta('Por favor, informe o SOBRENOME do usuário');
      return false;
    }
    if (!cpf) {
      mensagemAlerta('Por favor, informe o CPF do usuário');
      return false;
    }
    if (!sexo) {
      mensagemAlerta('Por favor, informe o SEXO do usuário');
      return false;
    }

    // validar se equipe e professor estão selecionados.

    if (!dadosEquipe.id) {
      mensagemAlerta('Por favor, selecione uma EQUIPE.');
      return false;
    }
    if (!professor.id && professores.length > 0) {
      mensagemAlerta('Por favor, selecione um professor.');
      return false;
    }

    if ((professores.length === 0 || professor.id === 'nao') && !inputProfessor) {
      mensagemAlerta('Por favor, informe um professor.');
      return false;
    }

    return true;

  }


  function camposPerfilAlterados() {
    const camposAlterados = [];

    if (usuarioFiliacao.nome !== nome) {
      camposAlterados.push({ campo: 'nome', valor: nome })
    }
    if (usuarioFiliacao.sobrenome !== sobrenome) {
      camposAlterados.push({ campo: 'sobrenome', valor: sobrenome })
    }
    if (usuarioFiliacao.cpf !== cpf) {
      camposAlterados.push({ campo: 'cpf', valor: cpf })
    }
    if (usuarioFiliacao.sexo !== sexo) {
      camposAlterados.push({ campo: 'sexo', valor: sexo })
    }
    if (usuarioFiliacao.dataNascimento !== dataNascimento) {
      camposAlterados.push({ campo: 'dataNascimento', valor: dataNascimento })
    }
    if (usuarioFiliacao.equipe !== dadosEquipe.id) {
      camposAlterados.push({ campo: 'equipe', valor: dadosEquipe.id })
    }

    return camposAlterados;
  }

  async function salvarFiliacao() {

    if (!validacao()) {
      return;
    }

    let titulo = ''
    let texto = ''
    if (!filiacao?.id) {
      titulo = 'Confirmar pedido de filiação'
      texto = 'Confirma pedido de filiação?'
    } else {
      if (filiacao.statusFiliacao === 4) {
        titulo = 'Confirmar renovação de filiação'
        texto = 'Confirma renovação de sua filiação?'
      } else {
        titulo = 'Confirmar alterações'
        texto = 'Salvar alterações?'
      }
    }

    if (!await mensagemConfirmacao(titulo, texto)) {
      return;
    }

    mensagemLoading('Salvando filiação...');
    setLoading(true);

    await new Promise((resolve) => {
      setTimeout(resolve, 1)
    })

    let dados = await gerarDadosFiliacao();


    if (!filiacao.id) {

      setTimeout(async () => {

        await new Promise((resolve) => {
          setTimeout(resolve, 1)
        })

        if (loadingRef.current) {
          window.location.reload();
        }

      }, 30000);

      const dadosFederacao = (await servicos.federacoes.doc(idFederacao).get()).data();

      dados = {
        ...dados,
        statusFiliacao: valorTotal() === 0 ? 1 : 0,
        numeroFiliacao: dadosFederacao.ultimafiliacao ? dadosFederacao.ultimafiliacao + 1 : 501
      };

      const docRef = await servicos.federacoes.doc(idFederacao).collection('filiacoes').add(dados);


      const atualizarFederacao = servicos.federacoes.doc(idFederacao).update({
        ultimafiliacao: dadosFederacao.ultimafiliacao ? dadosFederacao.ultimafiliacao + 1 : 501
      });
      const _salvarDadosDePagamento = salvarDadosDePagamento(docRef.id);
      const _atualizarInformacaoDaFiliacaoNoCadastroDeUsuario = atualizarInformacaoDaFiliacaoNoCadastroDeUsuario();


      let updateComplementar = { id: docRef.id };

      const promisesUploadArquivos = [
        uploadArquivoRgFBStorage(docRef.id),
        uploadArquivoFotoFBStorage(docRef.id)
      ];

      if (uploadArquivoCertificadoFaixaPreta) {
        promisesUploadArquivos.push(uploadArquivoCertificadoFaixaPretaFBStorage(docRef.id))
      }

      const [dadosUploadRg, dadosUploadFoto, dadosUploadCertificadoFaixaPreta] = await Promise.all(promisesUploadArquivos);


      updateComplementar = {
        ...updateComplementar,
        ...dadosUploadRg,
        ...dadosUploadFoto
      }

      if (dadosUploadCertificadoFaixaPreta) {
        updateComplementar = {
          ...updateComplementar,
          ...dadosUploadCertificadoFaixaPreta
        }
      }


      // await new Promise((resolve) => { });

      const atualizarArquivosAnexados = servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(docRef.id).update(updateComplementar);

      await Promise.all([
        atualizarArquivosAnexados,
        _salvarDadosDePagamento,
        _atualizarInformacaoDaFiliacaoNoCadastroDeUsuario,
        atualizarFederacao,
        enviarEmail()
      ]);


      let texto = 'Sua solicitação para filiação foi gerada e a Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente a página da federação.'
      let href = `/detalheFiliacao?f=${idFederacao}&fl=${docRef.id}`;


      if (valorTotal() > 0) {
        texto = "Você agora será redirencionado para a etapa de pagamento da sua filiação. Escolha a forma de pagamento de sua preferência.";
        href = `/filiacao/pagamento?fd=${idFederacao}&fl=${docRef.id}`;
      }

      Swal.fire('Concluído', texto, 'success').then(() => {
        window.location.href = href;
      })


      setLoading(false);

    } else if (filiacao.id) {

      const _pagamentoPendente = Boolean(pagamentoPendente?.id && pagamentoPendente?.status === 0)
      const _statusFiliacao =
        (statusFiliacao == 0 || _pagamentoPendente) ? 0 :
          (statusFiliacao == 2) ? 2 : 1;

      dados = { ...dados, statusFiliacao: _statusFiliacao };

      const promisesUploadArquivos = [];


      if (uploadArquivoRg) {
        promisesUploadArquivos.push(cadastraImagem(filiacao.id, 'rg', arquivoRg, null))
      }

      if (uploadArquivoFoto) {
        promisesUploadArquivos.push(cadastraImagem(filiacao.id, 'foto', arquivoFoto, null))
      }

      if (uploadArquivoCertificadoFaixaPreta) {
        promisesUploadArquivos.push(cadastraImagem(filiacao.id, 'certificadoFaixaPreta', arquivoCertificadoFaixaPreta, null));
      }


      const salvarPgto = [];
      if (filiacao.statusFiliacao === 4) {
        salvarPgto.push(salvarDadosDePagamento(filiacao.id));
        await enviarEmail();
      }

      const [dadosUploadRg, dadosUploadFoto, dadosUploadCertificadoFaixaPreta] = await Promise.all(promisesUploadArquivos);

      if (dadosUploadRg) {
        dados = {
          ...dados,
          ...dadosUploadRg
        }
      }
      if (dadosUploadFoto) {
        dados = {
          ...dados,
          ...dadosUploadFoto
        }
      }
      if (dadosUploadCertificadoFaixaPreta) {
        dados = {
          ...dados,
          ...dadosUploadCertificadoFaixaPreta
        }
      }

      await Promise.all([
        servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(filiacao.id).update(dados),
        enviarEmail(),
        ...salvarPgto
      ])


      let texto = ''
      if (filiacao.id && statusFiliacao === 4) {
        texto = 'A renovação da sua filiação foi solicitada e a Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente a página da federação .'
      }
      if (filiacao.id && statusFiliacao === 3) {
        texto = 'Documentação reenviada com sucesso. A Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente esta página .'
      }

      if (filiacao.id && [1, 2].includes(statusFiliacao)) {
        texto = 'Dados alterados com sucesso. A Federação irá avaliar suas alterações. Para acompanhar o andamento basta acessar novamente esta página.'
      }

      if (_pagamentoPendente) {
        texto = 'Dados alterados com sucesso. Você será agora redirecionado para a tela de pagamento.';
      }

      if(statusFiliacao === 4 && valorTotal() > 0){
        texto = "Você agora será redirencionado para a etapa de pagamento da sua filiação. Escolha a forma de pagamento de sua preferência.";

      }

      Swal.fire('Concluído', texto, 'success').then(() => {
        let href = `/detalheFiliacao?f=${idFederacao}&fl=${filiacao.id}`;
        if (pagamentoPendente?.id) {
          if (pagamentoPendente.status === 0) {
            href = `/filiacao/pagamento?fd=${idFederacao}&fl=${filiacao.id}`;
          }
        }
        if(statusFiliacao === 4 && valorTotal() > 0){
          href = `/filiacao/pagamento?fd=${idFederacao}&fl=${filiacao.id}`;
        }
        window.location.href = href;
      })


      setLoading(false);

    }


  }

  async function gerarDadosFiliacao() {

    const _camposPerfilAlterados = await alterarDadosDoCadastro();

    const possuiNovoValor = (valor) => {
      return _camposPerfilAlterados.some(item => item?.campo === valor)
    }
    const novoValor = (valor) => {
      return _camposPerfilAlterados.find(item => item?.campo === valor).valor
    }

    const _nomeFiliado = `${possuiNovoValor('nome') ?
      novoValor('nome') : usuarioFiliacao.nome} ${possuiNovoValor('sobrenome') ? novoValor('sobrenome') : usuarioFiliacao.sobrenome}`;

    const _cpfFiliado = possuiNovoValor('cpf') ? novoValor('cpf') : usuarioFiliacao.cpf;

    const _dataGraduacao = dataGraduacao.includes('/') ? converterMascaraParaData(dataGraduacao) : dataGraduacao


    let dados = {
      idFiliado: usuarioFiliacao.id,
      nomeFiliado: _nomeFiliado,
      urlAvatarFiliado: usuarioFiliacao.avatar,
      cpfFiliado: _cpfFiliado,
      emailFiliado: usuarioFiliacao.email,
      idResponsavel: usuarioFiliacao?.responsavel ? usuarioFiliacao.responsavel : '',
      graduacao: graduacao,
      grauPreta: grauPreta,
      dtGraduacao: firebase.firestore.Timestamp.fromDate(new Date(_dataGraduacao + ' 00:00')),
      dtCadastro: filiacao?.dtCadastro ? filiacao.dtCadastro : firebase.firestore.Timestamp.fromDate(new Date()),
      tipoUsuario: usuarioFiliacao.tipoUsuario,
      adquirirCarteirinhaFisica: incluirCarteirinhaFisica() ? '1' : '0',
      adquirirCarteirinhaDigital: incluirCarteirinhaDigital() ? '1' : '0',
      concordoTermo: concordoTermo
    }

    if (opcaoEntregaSelecionado) {

      dados = {
        ...dados,
        opcaoEntregaSelecionado: opcaoEntregaSelecionado
      }

      const enviarAoFiliado = '2';

      if (
        (opcaoEntregaSelecionado == enviarAoFiliado) ||
        federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado
      ) {
        dados = {
          ...dados,
          enderecoEntregaCarteirinha: {
            endereco: endereco,
            numero: numero,
            complemento: complemento,
            bairro: bairro,
            cidade: cidade,
            uf: uf,
            cep: cep
          }
        }
      }

    }

    if (incluirCarteirinhaFisica()) {

      let dadosCarteirinha = gerarDadosCarteirinha();

      dados = {
        ...dados,
        dadosCarteirinha: dadosCarteirinha
      };

    }


    return dados;


  }

  async function alterarDadosDoCadastro() {
    const _camposPerfilAlterados = [...camposPerfilAlterados()]

    if (_camposPerfilAlterados.length > 0) {
      if (_camposPerfilAlterados.some(item => item.campo === 'cpf')) {

        // Se houver alteração no CPF, verificar se o mesmo já não existe cadastrado antes de prosseguir

        const verificacaoNaCollectionUsuaruios = await utilitarios.verificaCampoDisponivel('cpf', cpf, servicos.usuarios);

        const verificacaoNaCollectionCompetidores = await utilitarios.verificaCampoDisponivel('cpf', cpf, firebase.firestore().collectionGroup('competidores'));

        if (!verificacaoNaCollectionUsuaruios || !verificacaoNaCollectionCompetidores) {
          mensagemAlerta('Este CPF já existe cadastrado no Sistema.');

          return;
        }
      }
    }


    if (((usuarioFiliacao.professor !== professor.id) && professor.id !== 'nao') || professor.id === 'nao') {



      if (professor.id === 'nao') {
        // Caso o usuário informe que o professor 'não' está na lista, realizar a inclusão deste professor antes de prossegir.

        const snapProfessor = await servicos.equipes.doc(dadosEquipe.id).collection('professores').add({ professor: inputProfessor });

        await servicos.equipes.doc(dadosEquipe.id).collection('professores').doc(snapProfessor.id).update({ id: snapProfessor.id })

        _camposPerfilAlterados.push({ campo: 'professor', valor: snapProfessor.id });

      } else {
        // Caso haja alteração para um professor que já exista, incluí-lo na lista de alterações
        _camposPerfilAlterados.push({ campo: 'professor', valor: professor.id });

      }
    }

    if (_camposPerfilAlterados.length > 0) {


      let dadosUpdatePerfil = {}

      for (let item of _camposPerfilAlterados) {
        dadosUpdatePerfil = {
          ...dadosUpdatePerfil,
          [item.campo]: item.valor
        }
      }

      if (['competidor', 'professor'].includes(usuarioFiliacao?.tipoUsuario)) {
        await servicos.usuarios.doc(usuarioFiliacao.id).update(dadosUpdatePerfil)
      } else if (usuarioFiliacao?.tipoUsuario === 'aluno' && usuarioFiliacao?.responsavel === usuario.id) {
        await servicos.usuarios.doc(usuarioFiliacao?.responsavel).collection('competidores').doc(usuarioFiliacao.id).update(dadosUpdatePerfil)
      }

    }

    return _camposPerfilAlterados
  }

  async function uploadArquivoRgFBStorage(idFiliacao) {

    let caminho = `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${arquivoRg.name}`;

    let downloadURL;

    while (true) {
      try {
        downloadURL = await utilitarios.uploadArquivo(caminho, arquivoRg);
        break;
      } catch (error) {
        console.log('Não foi possível realizar o upload!');
        console.log('Erro: ', error);
      }
    }

    if (!downloadURL) {

      mensagemAlerta('Não foi possível salvar seus dados de pagamento. Verifique sua conexão de internet e tente novamente!').then(() => {
        window.location.reload();
      });

      return;
    }

    const dadosUpload = {
      urlImagemRg: downloadURL,
      statusImagemRg: 1,
      nomeImagemRg: arquivoRg.name,
      dtAnexoRg: firebase.firestore.Timestamp.fromDate(new Date())
    }

    return dadosUpload;

  }
  async function uploadArquivoFotoFBStorage(idFiliacao) {

    let caminho = `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${arquivoFoto.name}`;

    let downloadURL;

    while (true) {
      try {
        downloadURL = await utilitarios.uploadArquivo(caminho, arquivoFoto);
        break;
      } catch (error) {
        console.log('Não foi possível realizar o upload!');
        console.log('Erro: ', error);
      }
    }

    if (!downloadURL) {
      mensagemAlerta('Não foi possível salvar seus dados de pagamento. Verifique sua conexão de internet e tente novamente!').then(() => {
        window.location.reload();
      });
      return;
    }

    const dadosUpload = {
      urlImagemFoto: downloadURL,
      statusImagemFoto: 1,
      nomeImagemFoto: arquivoFoto.name,
      dtAnexoFoto: firebase.firestore.Timestamp.fromDate(new Date())
    }

    return dadosUpload;

  }
  async function uploadArquivoCertificadoFaixaPretaFBStorage(idFiliacao) {

    let caminho = `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${arquivoCertificadoFaixaPreta.name}`;

    let downloadURL;

    while (true) {
      try {
        downloadURL = await utilitarios.uploadArquivo(caminho, arquivoCertificadoFaixaPreta);
        break;
      } catch (error) {
        console.log('Não foi possível realizar o upload!');
        console.log('Erro: ', error);
      }
    }

    if (!downloadURL) {
      mensagemAlerta('Não foi possível salvar seus dados de pagamento. Verifique sua conexão de internet e tente novamente!').then(() => {
        window.location.reload();
      });
      return;
    }

    const dadosUpload = {
      urlImagemCertificadoFaixaPreta: downloadURL,
      statusImagemCertificadoFaixaPreta: 1,
      nomeArquivoCertificadoFaixaPreta: arquivoCertificadoFaixaPreta.name,
      dtAnexoCertificadoFaixaPreta: firebase.firestore.Timestamp.fromDate(new Date())
    }

    return dadosUpload;

  }


  async function gerarPedidoCarteirinha(idFiliacao) {
    // Gerar pedido de carteirinha
    console.log(' == gerando carteirinha física ==');

    if (incluirCarteirinhaFisica()) {

      let dadosCarteirinha = gerarDadosCarteirinha(idFiliacao);

      const carteirinhaDocRef = await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').add(dadosCarteirinha)

      await servicos.filiacoes.doc(idFiliacao).collection('carteirinhas').doc(carteirinhaDocRef.id).update({ id: carteirinhaDocRef.id })

    }

  }

  function gerarDadosCarteirinha() {

    let dadosCarteirinha = {
      status: 1,
      valor: 0,
      tipoCarteirinha: 'fisica',
      dtPedido: firebase.firestore.Timestamp.fromDate(new Date()),
      // idFiliacao: idFiliacao,
      idFederacao: idFederacao,
      idFiliado: usuarioFiliacao.id,
      urlAvatarFiliado: usuarioFiliacao.avatar,
      nomeFiliado: `${usuarioFiliacao.nome} ${usuarioFiliacao.sobrenome}`,
      cpfFiliado: usuarioFiliacao.cpf,
      origemEmissao: 'filiacao'
    }

    if (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado) {
      dadosCarteirinha = {
        ...dadosCarteirinha,
        opcaoEntregaSelecionado: opcaoEntregaSelecionado
      }
    }

    const enviarAoFiliado = '2';

    if (
      opcaoEntregaSelecionado === enviarAoFiliado ||
      federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado
    ) {
      dadosCarteirinha = {
        ...dadosCarteirinha,
        enderecoEntregaCarteirinha: {
          endereco: endereco,
          numero: numero,
          complemento: complemento,
          bairro: bairro,
          cidade: cidade,
          uf: uf,
          cep: cep
        }
      }
    }

    return dadosCarteirinha;

  }

  async function atualizarInformacaoDaFiliacaoNoCadastroDeUsuario() {

    // Atualizar cadastro de usuário para adicionar as filiações

    if (['competidor', 'professor'].includes(usuarioFiliacao?.tipoUsuario)) {

      if (usuarioFiliacao?.filiacoes?.length > 0) {
        await servicos.usuarios.doc(usuarioFiliacao.id).update({
          filiacoes: [...usuarioFiliacao.filiacoes, idFederacao]
        })

      } else if (usuarioFiliacao?.filiacoes?.length === 0 || !usuarioFiliacao?.filiacoes) {
        await servicos.usuarios.doc(usuarioFiliacao.id).update(
          {
            filiacoes: [idFederacao]
          }
        )

      }

    } else if (usuarioFiliacao?.tipoUsuario === 'aluno' && usuarioFiliacao?.responsavel === usuario.id) {

      if (usuarioFiliacao?.filiacoes?.length > 0) {
        await servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
          filiacoes: [...usuarioFiliacao.filiacoes, idFederacao]
        })
      } else if (!usuarioFiliacao?.filiacoes || usuarioFiliacao?.filiacoes?.length === 0) {
        await servicos.usuarios.doc(usuario.id).collection('competidores').doc(usuarioFiliacao.id).update({
          filiacoes: [idFederacao]
        })
      }

    }

  }

  async function salvarDadosDePagamento(idFiliacao) {
    // Filiação Paga

    const paga = '2';
    const _valorFiliacao = federacao.regrasFiliacao?.tipoFiliacao == paga ? parseFloat(federacao.regrasFiliacao.valorPagamento) : 0;

    let dadosPgto = {
      status: valorTotal() === 0 ? 2 : 0,
      // tipoPagamento: 'manual',
      valorFiliacao: _valorFiliacao,
      valorTotal: valorTotal()
    }

    if (valorTotal() === 0) {
      dadosPgto = {
        ...dadosPgto,
        valorPago: 0,
        dtAprovado: firebase.firestore.Timestamp.fromDate(new Date()),
        aprovadoPor: "aprovação automática"
      }
    }



    if (incluirCarteirinhaFisica()) {
      dadosPgto = {
        ...dadosPgto,
        valorCarteirinha: parseFloat(federacao?.carteirinha?.valorCarteirinha)
      }

      const _cobrarTaxaEnvio = (federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2')) && federacao?.carteirinha?.cobrarTaxaEnvio === '1';

      if (_cobrarTaxaEnvio) {
        dadosPgto = {
          ...dadosPgto,
          valorTaxaEnvio: parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
        }
      }

    }

    if (incluirCarteirinhaDigital()) {
      dadosPgto = {
        ...dadosPgto,
        valorCarteirinhaDigital: parseFloat(federacao?.carteirinha?.valorCarteirinhaDigital)
      }
    }

    await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').add(dadosPgto)
      .then(docRef => {

        return servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').doc(docRef.id).update({ id: docRef.id });

      })

    // let dadosAdicionaisPgto = { id: pgtoDocRef.id }

    // if (uploadArquivoComprovante) {
    //   const dadosUploadComprovantePgto = await cadastraImagem(idFiliacao, 'comprovante', arquivoComprovante, pgtoDocRef.id)

    //   console.log('dadosUploadComprovantePgto: ', dadosUploadComprovantePgto);


    //   dadosAdicionaisPgto = {
    //     ...dadosAdicionaisPgto,
    //     ...dadosUploadComprovantePgto
    //   }

    // }

    // await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').doc(pgtoDocRef.id).update(dadosAdicionaisPgto);

  }

  async function enviarEmail() {
    const destinatario = federacao?.email;
    const enviarNotificao = (destinatario && federacao?.receberNotificacaoEmail)

    if (enviarNotificao && valorTotal() === 0) {
      const emailNotificacao = {
        to: destinatario,
        from: 'Procompetidor',
        message: {
          subject: 'Nova solicitação de filiação/renovação',
          html: '<html>' +
            '<body>' +
            '<p>Atenção! Você possui uma nova solicitação de filiação/renovação para aprovar. Verifique sua lista de filiados. </p>' +
            '<p>solcitante: <b>' + `${usuarioFiliacao?.nome} ${usuarioFiliacao?.sobrenome}` + ' </b></p>' +
            '<br/> <br/>' +
            '<h5>Equipe Procompetidor</h5>' +
            '</body>' +
            '</html>'
        }
      }

      servicos.mail.add(emailNotificacao)
      console.log('email enviado');

    }

  }

  function converterData(data) {
    return moment(new firebase.firestore.Timestamp(data.seconds, data.nanoseconds).toDate()).format('DD-MM-YYYY HH:mm')
  }


  function converterDataParaMascara(data) {
    const [ano, mes, dia] = data.split('-')
    return `${dia}/${mes}/${ano}`
  }
  function converterMascaraParaData(data) {
    const [dia, mes, ano] = data.split('/')
    return `${ano}-${mes}-${dia}`
  }


  const handleProfessor = (valor) => {
    setInputProfessor("")
    if (valor === "nao") {
      setProfessor({
        id: "nao"
      })
    } else {
      setProfessor(professores[valor])
    }

  }

  function valorTotal() {
    let valor = 0;
    const enviarAoFiliado = '2';
    const opcional = '3';
    const sim = '1';

    if (federacao?.regrasFiliacao?.tipoFiliacao === 2) {
      valor += parseFloat(federacao?.regrasFiliacao?.valorPagamento)
    }

    if (incluirCarteirinhaFisica()) {
      valor += parseFloat(federacao?.carteirinha?.valorCarteirinha)
    }

    if (incluirCarteirinhaDigital()) {
      valor += parseFloat(federacao?.carteirinha?.valorCarteirinhaDigital)
    }

    if (
      incluirCarteirinhaFisica() &&
      ((federacao?.carteirinha?.opcaoEntrega == enviarAoFiliado || (federacao?.carteirinha?.opcaoEntrega == opcional && opcaoEntregaSelecionado == enviarAoFiliado)) &&
        federacao?.carteirinha?.cobrarTaxaEnvio === sim)
    ) {
      valor += parseFloat(federacao?.carteirinha?.valorTaxaEnvio)
    }

    return valor;

  }

  function descricaoFormaPagamento(formaPgto) {

    let descricao = ''

    switch (formaPgto) {
      case "manual": descricao = "Manual"; break;
      case "ticket": descricao = "Boleto"; break;
      case "credit_card": descricao = "Cartão de Crédito"; break;
      case "bank_transfer": descricao = "PIX"; break;
      case "account_money": descricao = "Mercado Pago"; break;
      case "digital_wallet": descricao = "Mercado Pago"; break;
    }

    return descricao;
  }

  function exibirInputComprovante() {
    const filiacaoNaoAprovadaComPagamentoPendente = (filiacao.statusFiliacao !== 2 && pagamentoPendente.id);
    const semFiliacao = !filiacao.id;
    const filiacaoVencida = filiacao.statusFiliacao === 4 && !pagamentoPendente.id;

    return (
      (valorTotal() > 0) &&
      (filiacaoNaoAprovadaComPagamentoPendente ||
        semFiliacao || filiacaoVencida)
    )
  }
  function exibirInputDiplomaFaixaPreta() {
    return (federacao?.regrasFiliacao?.exigirDiplomaFaixaPreta && graduacao === 'PRETA')
  }

  function editavel() {
    return (usuarioFiliacao?.id && (!filiacao?.id || (filiacao?.id && filiacao?.statusFiliacao === 4)))
  }

  function carteirinhaFisicaOpcional() {
    const opcional = '2';
    const paga = '1';
    return (federacao?.carteirinha.carteirinhaFisica == opcional && federacao?.carteirinha?.tipoAquisicao == paga)
  }
  function carteirinhaDigitalOpcional() {
    const opcional = '2';
    const paga = '1';
    return (federacao?.carteirinha.carteirinhaDigital == opcional && federacao?.carteirinha?.tipoAquisicaoCarteirinhaDigital == paga)
  }

  function carteirnhasOpcionais() {
    return (carteirinhaFisicaOpcional() || carteirinhaDigitalOpcional())
  }

  function incluirCarteirinhaFisica() {
    const sim = '1';
    const opcional = '2';
    return (federacao?.carteirinha?.carteirinhaFisica == sim ||
      (federacao?.carteirinha?.carteirinhaFisica == opcional && adquirirCarteirinhaFisica == sim))
  }


  function incluirCarteirinhaDigital() {
    const sim = '1';
    const opcional = '2';
    return (
      federacao?.carteirinha?.carteirinhaDigital == sim ||
      (federacao?.carteirinha?.carteirinhaDigital == opcional && adquirirCarteirinhaDigital == sim))
  }

  return (
    <div className="body-wrapper blank-loder">
      <Header />
      <div class="section_fullwidth">
        <div class="headline headline_img">
          <div class="headline__inner">
            <div class="headline__title">
              <h1>Filiação</h1>
              {filiacao.numeroFiliacao && (
                <p style={{ position: 'relative', top: mediaQuery.matches ? '-35px' : '-40px', left: mediaQuery.matches ? '206px' : '278px', fontSize: '21px' }}>Nº {filiacao.numeroFiliacao.toString().padStart(4, '0')}</p>
              )}
            </div>
            <div class="headline__description">
              <span>Faça aqui a sua filiação ou acompanhe o seu andamento</span>
            </div>

            {filiacao?.id && (
              <>
                <p style={{ position: 'relative', top: '44px', left: '5px', fontSize: '21px' }}><b>Situação:
                  {filiacao.statusFiliacao === 0 && (<span style={{ color: 'orange' }}> Pendente de Pagamento</span>)}
                  {filiacao.statusFiliacao === 1 && (<span style={{ color: 'orange' }}> Aguardando aprovação da documentação</span>)}
                  {filiacao.statusFiliacao === 2 && (<span style={{ color: 'green' }}> Aprovada</span>)}
                  {filiacao.statusFiliacao === 3 && (<span style={{ color: 'red' }}> Documentação Reprovada</span>)}
                  {filiacao.statusFiliacao === 4 && (<span style={{ color: 'red' }}> Vencida (pendente de renovação)</span>)}
                </b></p>



              </>
            )}
          </div>

        </div>
      </div>
      <div class="section_default section-single-class-layout clearfix">
        <main class="section_default">
          <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
            <div class="wpb_column vc_column_container vc_col-sm-12">
              <div class="vc_column-inner ">
                <div class="wpb_wrapper">
                  <div class="wpb_text_column wpb_content_element ">
                    <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                    </div>

                    <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                      <div className={mediaQuery.matches ? 'vc_col-sm-12' : ''} style={{ display: !mediaQuery.matches ? 'flex' : 'block' }}>

                        <div class=" vc_col-sm-4">
                          <div class="wpb_single_image wpb_content_element vc_align_left">
                            <figure class="wpb_wrapper vc_figure">
                              <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey">
                                <img width="1920" height="1401"
                                  src={federacao?.urlImagemFederacao}
                                  class="vc_single_image-img attachment-full" alt=""
                                  srcset={federacao?.urlImagemFederacao}
                                  sizes="(max-width: 1920px) 100vw, 1920px" /></div>
                            </figure>

                          </div>
                        </div>

                        <div class="wpb_column vc_column_container vc_col-sm-8">

                          <div class="vc_column-inner ">
                            <div class="wpb_wrapper">
                              <div class="wpb_widgetised_column wpb_content_element">
                                <div class="wpb_wrapper">
                                  <div style={{ margin: mediaQuery.matches ? '10px 0' : '0 0 0 10vw' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                    <h3 style={{ lineHeight: mediaQuery.matches ? 'initial' : 'normal' }}>Dados da Federação / Agremiação</h3>
                                  </div>
                                  <div role="form" class="row " >
                                    <p class="contact-form-name vc_col-sm-12">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Nome</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.nome}
                                          disabled
                                          placeholder="Nome *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>DDD</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.ddd}
                                          disabled
                                          placeholder="DDD *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-3">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Telefone</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.telefone}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-7">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Email</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.email}
                                          disabled
                                          placeholder="Celular *" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-6">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Cidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.cidade}
                                          disabled
                                          placeholder="Cidade" style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-2">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>UF</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={federacao?.uf}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>
                                    <p class="contact-form-name vc_col-sm-4">
                                      <span class="wpcf7-form-control-wrap your-name">
                                        <h6>Modalidade</h6>
                                        <input
                                          type="text" name="your-name" size="40"
                                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                          aria-required="true" aria-invalid="false"
                                          value={modalidade}
                                          disabled
                                          style={{ background: '#F5F5F5' }} />
                                      </span>
                                    </p>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                        {/* Dados Bancários - Início */}
                        {false && (
                          <div class="wpb_column vc_column_container vc_col-sm-12">

                            <div class="vc_column-inner ">
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">

                                    <div role="form" class="row " >

                                      <h4 style={{ textAlign: 'center', display: 'block' }}>*Dados Bancários</h4>

                                      <p class="contact-form-name vc_col-sm-8">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Nome Favorecido</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.favorecido}
                                            disabled
                                            style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>CPF/CNPJ</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.cpfCnpj}
                                            disabled
                                            style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Agência</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.agencia}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Conta</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.conta}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-4">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Banco</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.banco}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-3">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Tipo Chave Pix</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.tipoChavePix}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>
                                      <p class="contact-form-name vc_col-sm-9">
                                        <span class="wpcf7-form-control-wrap your-name">
                                          <h6>Chave Pix</h6>
                                          <input
                                            type="text" name="your-name" size="40"
                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                            aria-required="true" aria-invalid="false"
                                            value={federacao?.dadosBancarios?.chavePix}
                                            disabled style={{ background: '#F5F5F5' }} />
                                        </span>
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Dados Bancários - Fim */}
                      </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                      <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>
                        <div className='wpb_row row'>

                          <div class=" vc_col-sm-12">
                            <div class=" vc_col-sm-3" style={{ paddingTop: '67px' }}>
                              <div class="vc_single_image-wrapper package-style-1   vc_box_border_grey" style={{ background: ` url(${usuarioFiliacao?.avatar ? usuarioFiliacao?.avatar : profile}) no-repeat center/contain`, height: '250px' }}>
                              </div>
                            </div>

                            <div class="wpb_column vc_column_container vc_col-sm-9">

                              <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                  <div class="wpb_widgetised_column wpb_content_element">
                                    <div class="wpb_wrapper">
                                      <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px 0', textAlign: 'center' }}>
                                        <h3>Dados do Filiado</h3>
                                      </div>
                                      <div role="form" class="row " >

                                        {usuario.usuario === 2 && (
                                          <p class="contact-form-name vc_col-sm-4">
                                            <span class="wpcf7-form-control-wrap your-name">
                                              <h6>Nome</h6>
                                              <input
                                                type="text" name="your-name" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true" aria-invalid="false"
                                                value={nome}
                                                disabled={!editavel()}
                                                placeholder="Nome *" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setNome(e.target.value)} />
                                            </span>
                                          </p>
                                        )}

                                        {usuario.usuario === 3 && (
                                          <p class="contact-form-name vc_col-sm-4">
                                            <span class="wpcf7-form-control-wrap your-name"
                                            >
                                              <h6>Nome</h6>
                                              <select name="select" id="select"
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%' }} onChange={handleCompetidor} value={usuarioSelecionado}>
                                                <option value="">Selecione o filiado </option>
                                                <option value="eu">{usuario.nome} - (Responsável) </option>
                                                {competidores.sort((a, b) => a.nome - b.nome).map((c, index) =>
                                                  <option value={index} >{c.nome} - (Aluno)</option>
                                                )}
                                              </select>
                                            </span>
                                          </p>
                                        )}

                                        <p class="contact-form-name vc_col-sm-5">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>sobrenome</h6>
                                            <input
                                              type="text" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={sobrenome}
                                              disabled={!editavel()}
                                              placeholder="Sobrenome *" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setSobrenome(e.target.value)} />
                                          </span>
                                        </p>

                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>CPF</h6>
                                            <input
                                              type="text" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={cpf}
                                              disabled={!editavel()}
                                              placeholder="Cpf *" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setCpf(e.target.value)} />
                                          </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Sexo</h6>
                                            <select name="select" id="select"
                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%', background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setSexo(e.target.value)} value={sexo} disabled={!editavel()}>
                                              <option value="">Selecione o sexo </option>
                                              <option value="masculino"> Masculino</option>
                                              <option value="feminino">Feminino</option>

                                            </select>
                                          </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Data Nascimento</h6>
                                            <input
                                              type="date" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={dataNascimento}
                                              disabled={!editavel()}
                                              placeholder="Idade *" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setDataNascimento(e.target.value)} />
                                          </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Tipo</h6>
                                            <input
                                              type="text" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={usuarioFiliacao?.tipoUsuario ? usuarioFiliacao.tipoUsuario : ''}
                                              disabled
                                              placeholder="Tipo *" style={{ background: '#F5F5F5' }} />
                                          </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Sua Equipe</h6>
                                            <input type='text' name="select"
                                              class="form-control"
                                              value={dadosEquipe.id ? dadosEquipe.equipe : ''}
                                              disabled={!editavel()}
                                              placeholder={'Selecione uma equipe'}
                                              style={{ background: editavel() ? '#FFF' : '#F5F5F5', cursor: "pointer" }}
                                              onClick={() => setModalShow(true)}
                                            />

                                          </span>
                                        </p>
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name">
                                            <h6>Seu Professor</h6>
                                            {dadosEquipe.id && professores?.length === 0 ?
                                              <input
                                                type="text" name="your-name" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true" aria-invalid="false"
                                                value={inputProfessor}
                                                disabled={!editavel()}
                                                style={{ background: editavel() ? '#FFF' : '#F5F5F5' }}
                                                onChange={(e) => setInputProfessor(e.target.value)}
                                                placeholder="Nome do Professor *" />
                                              :
                                              <select name="select" id="select" class="form-control" onChange={(e) => handleProfessor(e.target.value)} disabled={!editavel()} style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} value={professores.findIndex(prof => prof.id === usuarioFiliacao.professor)}>
                                                <option value="">Escolha seu Professor </option>
                                                <option value="nao">Não está na lista </option>
                                                {professores.sort((a, b) => {
                                                  if (a.professor < b.professor)
                                                    return -1;
                                                  if (a.professor > b.professor)
                                                    return 1;
                                                }).map((eq, index) =>
                                                  <option value={index} selected={usuario.professor === eq.id}>{eq.professor.toUpperCase()}</option>
                                                )}
                                              </select>
                                            }

                                          </span>
                                        </p>
                                        {professor?.id === 'nao' && (
                                          <p class="contact-form-name vc_col-sm-3">
                                            <span class="wpcf7-form-control-wrap your-name">
                                              <h6>Nome Professor</h6>
                                              <input
                                                type="text" name="your-name" size="40"
                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                aria-required="true" aria-invalid="false"
                                                value={inputProfessor}
                                                disabled={!editavel()}
                                                placeholder="Nome Professor*" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setInputProfessor(e.target.value)} />
                                            </span>
                                          </p>
                                        )}
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name"
                                          >
                                            <h6>Graduação</h6>
                                            <select name="select" id="select"
                                              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%', background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setGraduacao(e.target.value)} value={graduacao} disabled={!editavel()}>
                                              <option value="">Selecione a graduação </option>
                                              {graduacoes.sort((a, b) => a.posicao - b.posicao).map((faixa) =>
                                                <option value={faixa.descricao} selected={faixa.descricao === graduacao}>{faixa.descricao}</option>
                                              )}
                                            </select>
                                          </span>
                                        </p>
                                        {exibirGrausPreta() && (
                                          <p class="contact-form-name vc_col-sm-2">
                                            <span class="wpcf7-form-control-wrap your-name"
                                            >
                                              <h6>Grau</h6>
                                              <select name="select" id="select"
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" style={{ minWidth: '100%', background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setGrauPreta(e.target.value)} value={grauPreta} disabled={!editavel()}>
                                                <option value="">Selecione o grau </option>
                                                {grausPreta.sort((a, b) => a.grau - b.grau).map((grau) =>
                                                  <option value={grau.grau}>{grau.grau == '0' ? 'sem grau' : grau.grau}</option>
                                                )}
                                              </select>
                                            </span>
                                          </p>
                                        )}
                                        <p class="contact-form-name vc_col-sm-3">
                                          <span class="wpcf7-form-control-wrap your-name"
                                          >
                                            <h6>{graduacao === 'BRANCA' ? 'Treina desde:' : 'Data Graduação'}</h6>

                                            <InputMask
                                              mask="99/99/9999"
                                              value={converterDataParaMascara(dataGraduacao)}
                                              onChange={(e) => setDataGraduacao(e.target.value)}
                                              placeholder="MM/DD/YYYY"
                                              disabled={!editavel()}

                                            >
                                              {(inputProps) => (
                                                <input
                                                  type="text" name="your-name" size="40"
                                                  class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                  aria-required="true" aria-invalid="false"
                                                  // value={dataGraduacao}
                                                  // disabled={!editavel()}
                                                  // placeholder="Data graduação *"
                                                  style={{ background: editavel() ? '#FFF' : '#F5F5F5' }}
                                                // onChange={(e) => setDataGraduacao(e.target.value)} 
                                                />
                                              )}
                                            </InputMask>

                                            {/* <input
                                              type="text" name="your-name" size="40"
                                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                              aria-required="true" aria-invalid="false"
                                              value={dataGraduacao}
                                              // disabled={!editavel()}
                                              placeholder="Data graduação *" style={{ background: editavel() ? '#FFF' : '#F5F5F5' }} onChange={(e) => setDataGraduacao(e.target.value)} /> */}

                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>


                    </div>


                    {/* Dados da Filiação - Início */}

                    {usuarioFiliacao.id && (
                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                        <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>

                          <div className=' wpb_row row'>

                            <div class="wpb_column vc_column_container vc_col-sm-12">



                              <div class="vc_column-inner">
                                <div class="wpb_wrapper">
                                  <div class="wpb_widgetised_column wpb_content_element">
                                    <div class="wpb_wrapper">

                                      {/* <div class=" vc_col-sm-12">
                                        <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px 0', textAlign: 'center' }}>
                                          <h3>Carteirinha</h3>
                                        </div>
                                      </div> */}

                                      {carteirnhasOpcionais() && (
                                        <div class="wpb_column vc_column_container vc_col-sm-12" style={{ marginBottom: '10px' }}>

                                          {carteirinhaDigitalOpcional() && (
                                            <div class="contact-form-name vc_col-sm-3">
                                              <span class="wpcf7-form-control-wrap your-name">
                                                <h6>Adquirir Carteirinha Digital?</h6>
                                                <select name="select" id="select" class="form-control"
                                                  disabled={([0, 1, 2, 3].includes(filiacao?.statusFiliacao))} onChange={(e) => setAdquirirCarteirinhaDigital(e.target.value)} value={adquirirCarteirinhaDigital}>
                                                  <option value="">Selecionar </option>
                                                  <option value="1">Sim </option>
                                                  <option value="0">Não </option>

                                                </select>
                                              </span>
                                            </div>
                                          )}

                                          {carteirinhaFisicaOpcional() && (
                                            <div class="contact-form-name vc_col-sm-3">
                                              <span class="wpcf7-form-control-wrap your-name">
                                                <h6>Adquirir Carteirinha Física?</h6>
                                                <select name="select" id="select" class="form-control"
                                                  disabled={([0, 1, 2, 3].includes(filiacao?.statusFiliacao))} onChange={(e) => {
                                                    setOpcaoEntregaSelecionado('')
                                                    setAdquirirCarteirinhaFisica(e.target.value)
                                                  }} value={adquirirCarteirinhaFisica}>
                                                  <option value="">Selecionar </option>
                                                  <option value="1">Sim </option>
                                                  <option value="0">Não </option>

                                                </select>
                                              </span>
                                            </div>
                                          )}



                                        </div>
                                      )}





                                      <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px 0', textAlign: 'center' }}>
                                        <h3>Anexar documentos para a filiação</h3>
                                      </div>

                                      {/* Tabela Extrato */}
                                      <table style={{ width: "100%", marginTop: 20 }}>
                                        <thead>
                                          <tr >
                                            <th class="schedule-table__day">Extrato</th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <table style={{ width: "100%" }} className="3">
                                        <tbody>

                                          <tr>
                                            <td>Filiação</td>
                                            <td>{federacao?.regrasFiliacao?.tipoFiliacao == 2 ? parseFloat(federacao?.regrasFiliacao?.valorPagamento).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'R$ 0,00'}</td>
                                          </tr>
                                          {incluirCarteirinhaFisica() && (
                                            <tr>
                                              <td>Carteirinha Física</td>
                                              <td>{parseFloat(federacao?.carteirinha?.valorCarteirinha).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                            </tr>
                                          )}
                                          {incluirCarteirinhaDigital() && (
                                            <tr>
                                              <td>Carteirinha Digital</td>
                                              <td>{parseFloat(federacao?.carteirinha?.valorCarteirinhaDigital).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                            </tr>
                                          )}

                                          {((federacao?.carteirinha?.opcaoEntrega == '2' || (federacao?.carteirinha?.opcaoEntrega == '3' && opcaoEntregaSelecionado == '2'))
                                            && federacao?.carteirinha?.cobrarTaxaEnvio === '1') && (
                                              <tr>
                                                <td>Taxa de Envio</td>
                                                <td>{parseFloat(federacao?.carteirinha?.valorTaxaEnvio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                              </tr>
                                            )}

                                          <tr>
                                            <td><b>Total a Pagar</b></td>
                                            <td><b>{valorTotal().toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></td>
                                          </tr>

                                        </tbody>
                                      </table>
                                      {/* Tabela Extrato - Fim */}
                                      {(incluirCarteirinhaFisica() &&
                                        federacao?.carteirinha?.opcaoEntrega === '3' && federacao?.carteirinha?.gerarPedidoComAFiliacao === '1') && (
                                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px 14px', width: '100%', marginTop: '15px' }}>
                                            <FormControl component="fieldset" disabled={([0, 1, 2, 3].includes(filiacao?.statusFiliacao))}>
                                              <h6>Selecione uma opção de recebimento da carteirinha</h6>
                                              <RadioGroup
                                                aria-label="options"
                                                name="options"
                                                value={opcaoEntregaSelecionado}
                                                onChange={(e) => setOpcaoEntregaSelecionado(e.target.value)}
                                                style={{ display: 'flex', flexDirection: 'row' }}
                                              >
                                                <FormControlLabel value="1" control={<Radio />} label="Retirar na Federação" />
                                                <FormControlLabel value="2" control={<Radio />} label="Enviar ao Filiado" />

                                              </RadioGroup>
                                            </FormControl>

                                            {opcaoEntregaSelecionado == '1' && (
                                              <div className='col-md-12' style={{ marginLeft: '80px' }}>

                                                <b>Endereço/Local de entrega da carteirinha: </b>{ReactHtmlParser(federacao?.carteirinha?.enderecoRetiradaCarteirinha)}

                                              </div>
                                            )}

                                            {opcaoEntregaSelecionado == '2' && (


                                              <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px', width: '100%', marginTop: '15px', marginBottom: '15px' }} className='vc_col-sm-12' >

                                                <p class="contact-form-name vc_col-sm-12">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Endereço de Entrega:</h6>
                                                  </span>
                                                </p>

                                                <p class="contact-form-name vc_col-sm-4">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Endereço</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setEndereco(e.target.value)}
                                                      value={endereco}
                                                      placeholder="Ex: Rua das Oruídeas *" />
                                                  </span>
                                                </p>

                                                <p class="contact-form-name vc_col-sm-2">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>N°</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setNumero(e.target.value)}
                                                      value={numero}
                                                      placeholder="N°" />
                                                  </span>
                                                </p>

                                                <p class="contact-form-name vc_col-sm-3">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Complemento</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setComplemento(e.target.value)}
                                                      value={complemento}
                                                      placeholder="Ex: ap.15" />
                                                  </span>
                                                </p>

                                                <p class="contact-form-name vc_col-sm-3">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Bairro</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setBairro(e.target.value)}
                                                      value={bairro}
                                                      placeholder="Bairro *" />
                                                  </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Cidade</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setCidade(e.target.value)}
                                                      value={cidade}
                                                      placeholder="Cidade *" />
                                                  </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>Estado</h6>
                                                    <select name="select" id="select" className="wpcf7-form-control wpcf7-text wpcf7-validates-as-require" onChange={(e) => setUf(e.target.value)} >
                                                      <option value="">Escolha</option>
                                                      {UFs.map(_uf => (
                                                        <option value={_uf} selected={_uf === uf}>{_uf}</option>
                                                      )
                                                      )}
                                                    </select>

                                                  </span>
                                                </p>



                                                <p class="contact-form-name vc_col-sm-2">
                                                  <span class="wpcf7-form-control-wrap your-name">
                                                    <h6>CEP</h6>
                                                    <input
                                                      type="text" name="your-name" size="40"
                                                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                      aria-required="true" aria-invalid="false"
                                                      onChange={(e) => setCep(e.target.value)}
                                                      value={cep}
                                                      placeholder="CEP *" />
                                                  </span>
                                                </p>
                                              </div>
                                            )}


                                          </div>
                                        )}

                                      {(incluirCarteirinhaFisica() &&
                                        federacao?.carteirinha?.opcaoEntrega === '1') && (
                                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '5px 14px', width: '100%', marginTop: '15px' }}>

                                            <div className='col-md-12' style={{ marginLeft: '80px' }}>

                                              <b>Endereço/Local de entrega da carteirinha: </b>{ReactHtmlParser(federacao?.carteirinha?.enderecoRetiradaCarteirinha)}

                                            </div>
                                          </div>
                                        )}



                                      {/* <p><b>*Anexe abaixo os documentos necessários para a sua filiação</b></p>
                {federacao?.regrasFiliacao?.tipoFiliacao === 2 && (
                  <p><b>**Valor da filiação: {parseFloat(federacao.regrasFiliacao.valorPagamento).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></p>
                )}
                {federacao?.regrasFiliacao?.tipoFiliacao === 1 && (
                  <p><b>**filiação GRATUITA</b></p>
                )} */}

                                      <div role="form" class="row " >

                                        {/* Tabela anexar arquivos - Inicio */}
                                        <table style={{ width: "100%", marginTop: 20 }}>
                                          <thead>
                                            <tr >
                                              <th class="schedule-table__day">
                                                Anexe aqui os documentos necessários para {filiacao.statusFiliacao === 4 && (<span style={{ textDecoration: 'underline', color: 'red' }}>Renovar </span>)}sua filiação</th>
                                            </tr>
                                          </thead>
                                        </table>
                                        {!mediaQuery.matches && (
                                          <table style={{ width: "100%" }} className="3">
                                            <thead style={{ height: '15px' }}>
                                              <tr style={{ background: '#121212', color: 'white' }}>
                                                <th className="" style={{ width: '250px' }} >Documento: </th>
                                                <th className="">Anexar: </th>
                                                <th className="" style={{ width: '250px' }}>Status: </th>
                                                <th className="">Observação:</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>RG (frente-verso)</td>
                                                <td onClick={() => { refRgFile.current.click() }}>
                                                  <div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                    <input
                                                      ref={refRgFile}
                                                      type="file"
                                                      id="file-input1"
                                                      className="file-input"
                                                      onChange={(e) => handleFotoRg(e)}
                                                    />
                                                    <label htmlFor="file-input" className="file-label">
                                                      {mediaQuery.matches ? 'Selecionar' : 'Selecione um arquivo'}
                                                    </label>
                                                    <p style={{ margin: '2px 10px' }} className="file-name">{fotoRg}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  {(!filiacao.id && !uploadArquivoRg) && 'Não Anexado'}
                                                  {(uploadArquivoRg) && 'Anexado'}
                                                  {(filiacao.statusImagemRg === 1 && !uploadArquivoRg) && (
                                                    <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                  )}
                                                  {(filiacao.statusImagemRg === 2 && !uploadArquivoRg) && (
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                  )}
                                                  {(filiacao.statusImagemRg === 3 && !uploadArquivoRg) && (
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                  )}
                                                  {(filiacao.statusImagemRg === 4 && !uploadArquivoRg) && (
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                  )}
                                                </td>
                                                <td>
                                                  {(filiacao.statusImagemRg === 3) ? (
                                                    <div
                                                      className='badge-doc-reprovada'
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => mensagemReprovado(filiacao.observacaoRg)} data-toggle="modal" data-target="#modal"
                                                    >
                                                      <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                    </div>
                                                  ) : <div style={{ visibility: 'hidden' }}>|</div>}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Foto 3x4</td>
                                                <td onClick={() => { refDocumentoFile.current.click() }}>
                                                  <div className="file-input-wrapper">
                                                    <input
                                                      ref={refDocumentoFile}
                                                      type="file"
                                                      id="file-input2"
                                                      className="file-input"
                                                      onChange={(e) => handleFotoDocumento(e)}
                                                    />
                                                    <label htmlFor="file-input" className="file-label">
                                                      Selecione um arquivo
                                                    </label>
                                                    <p style={{ margin: '2px 10px' }} className="file-name">{fotoDocumento}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  {(!filiacao.id && !uploadArquivoFoto) && 'Não Anexado'}
                                                  {(uploadArquivoFoto) && 'Anexado'}
                                                  {(filiacao.statusImagemFoto === 1 && !uploadArquivoFoto) && (
                                                    <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                  )}
                                                  {(filiacao.statusImagemFoto === 2 && !uploadArquivoFoto) && (
                                                    <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                  )}
                                                  {(filiacao.statusImagemFoto === 3 && !uploadArquivoFoto) && (
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                  )}
                                                  {(filiacao.statusImagemFoto === 4 && !uploadArquivoFoto) && (
                                                    <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                  )}
                                                </td>
                                                <td>
                                                  {(filiacao.statusImagemFoto === 3) && (
                                                    <div
                                                      className='badge-doc-reprovada'
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => mensagemReprovado(filiacao.observacaoFoto)} data-toggle="modal" data-target="#modal"
                                                    >
                                                      <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                    </div>
                                                  )}
                                                </td>

                                              </tr>

                                              {exibirInputDiplomaFaixaPreta() && (
                                                <tr>
                                                  <td>Certificado Faixa Preta</td>
                                                  <td onClick={() => { refCertificadoFaixaPretaFile.current.click() }}>
                                                    <div className="file-input-wrapper">
                                                      <input
                                                        ref={refCertificadoFaixaPretaFile}
                                                        type="file"
                                                        id="file-input2"
                                                        className="file-input"
                                                        onChange={(e) => handleCertificadoFaixaPreta(e)}
                                                      />
                                                      <label htmlFor="file-input" className="file-label">
                                                        Selecione um arquivo
                                                      </label>
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{nomeArquivoCertificadoFaixaPreta}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {(!filiacao.id && !uploadArquivoCertificadoFaixaPreta) && 'Não Anexado'}
                                                    {(uploadArquivoCertificadoFaixaPreta) && 'Anexado'}
                                                    {(filiacao.statusImagemCertificadoFaixaPreta === 1 && !uploadArquivoCertificadoFaixaPreta) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(filiacao.statusImagemCertificadoFaixaPreta === 2 && !uploadArquivoCertificadoFaixaPreta) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemCertificadoFaixaPreta === 3 && !uploadArquivoCertificadoFaixaPreta) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemCertificadoFaixaPreta === 4 && !uploadArquivoCertificadoFaixaPreta) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {(filiacao.statusImagemCertificadoFaixaPreta === 3) && (
                                                      <div
                                                        className='badge-doc-reprovada'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => mensagemReprovado(filiacao.observacaoCertificadoFaixaPreta)} data-toggle="modal" data-target="#modal"
                                                      >
                                                        <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                      </div>
                                                    )}
                                                  </td>

                                                </tr>
                                              )}

                                              {/* {exibirInputComprovante() && (
                                                // Obs: A solicitação de pagamento só deve ocorrer se o tipoFiliacao for pago (2)
                                                <tr>
                                                  <td>Comprovante de Pagamento</td>
                                                  <td onClick={() => { refComprovanteFile.current.click() }}>
                                                    <div className="file-input-wrapper">
                                                      <input
                                                        ref={refComprovanteFile}
                                                        type="file"
                                                        id="file-input2"
                                                        className="file-input"
                                                        onChange={(e) => handleFotoComprovante(e)}
                                                      />
                                                      <label htmlFor="file-input" className="file-label">
                                                        Selecione um arquivo
                                                      </label>
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                    {(uploadArquivoComprovante) && 'Anexado'}
                                                    {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {pagamentoPendente.status === 3 && (
                                                      <div
                                                        className='badge-doc-reprovada'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => mensagemReprovado(pagamentoPendente.observacaoComprovante)} data-toggle="modal" data-target="#modal"
                                                      >
                                                        <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              )} */}

                                            </tbody>
                                          </table>
                                        )}

                                        {/* versão mobile */}

                                        {mediaQuery.matches && (
                                          <>
                                            <table style={{ width: "100%", marginTop: '30px' }} className="3">
                                              <tbody>
                                                <tr>
                                                  <td style={{ width: '111px' }}>Documento</td>
                                                  <td>RG (frente-verso)</td>
                                                </tr>
                                                <tr>
                                                  <td><div className="file-input-wrapper" onClick={() => { refRgFile.current.click() }}>
                                                    <label htmlFor="file-input" className="file-label" style={{ boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)', height: '40px', display: 'flex', alignItems: 'center' }}>
                                                      Selecionar
                                                    </label>
                                                  </div></td>

                                                  <td><div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                    <input
                                                      ref={refRgFile}
                                                      type="file"
                                                      id="file-input1"
                                                      className="file-input"
                                                      onChange={(e) => handleFotoRg(e)}
                                                    />
                                                    <p style={{ margin: '2px 10px' }} className="file-name">{fotoRg}</p>
                                                  </div></td>
                                                </tr>
                                                <tr>
                                                  <td>Status</td>
                                                  <td>
                                                    {(!filiacao.id && !uploadArquivoRg) && 'Não Anexado'}
                                                    {(uploadArquivoRg) && 'Anexado'}
                                                    {(filiacao.statusImagemFoto === 1 && !uploadArquivoRg) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 2 && !uploadArquivoRg) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 3 && !uploadArquivoRg) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 4 && !uploadArquivoRg) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Observação</td>
                                                  <td>
                                                    {(filiacao.statusImagemFoto === 3) && (
                                                      <div
                                                        className='badge-doc-reprovada'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => mensagemReprovado(filiacao.observacaoFoto)} data-toggle="modal" data-target="#modal"
                                                      >
                                                        <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>

                                              </tbody>

                                            </table>

                                            <table style={{ marginTop: '30px' }}>
                                              <tbody>
                                                <tr>
                                                  <td style={{ width: '111px' }}>Documento</td>
                                                  <td>Foto 3x4</td>
                                                </tr>
                                                <tr>
                                                  <td><div className="file-input-wrapper" onClick={() => { refDocumentoFile.current.click() }}>
                                                    <label htmlFor="file-input" className="file-label" style={{ boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)', height: '40px', display: 'flex', alignItems: 'center' }}>
                                                      Selecionar
                                                    </label>
                                                  </div></td>

                                                  <td><div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                    <input
                                                      ref={refDocumentoFile}
                                                      type="file"
                                                      id="file-input1"
                                                      className="file-input"
                                                      onChange={(e) => handleFotoDocumento(e)}
                                                    />
                                                    <p style={{ margin: '2px 10px' }} className="file-name">{fotoDocumento}</p>
                                                  </div></td>
                                                </tr>
                                                <tr>
                                                  <td>Status</td>
                                                  <td>
                                                    {(!filiacao.id && !uploadArquivoFoto) && 'Não Anexado'}
                                                    {(uploadArquivoFoto) && 'Anexado'}
                                                    {(filiacao.statusImagemFoto === 1 && !uploadArquivoFoto) && (
                                                      <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 2 && !uploadArquivoFoto) && (
                                                      <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 3 && !uploadArquivoFoto) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                    )}
                                                    {(filiacao.statusImagemFoto === 4 && !uploadArquivoFoto) && (
                                                      <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Observação</td>
                                                  <td>
                                                    {(filiacao.statusImagemFoto === 3) && (
                                                      <div
                                                        className='badge-doc-reprovada'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => mensagemReprovado(filiacao.observacaoFoto)} data-toggle="modal" data-target="#modal"
                                                      >
                                                        <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            {exibirInputDiplomaFaixaPreta() && (
                                              <table style={{ marginTop: '30px' }}>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ width: '111px' }}>Documento</td>
                                                    <td>Certificado Faixa Preta</td>
                                                  </tr>
                                                  <tr>
                                                    <td><div className="file-input-wrapper" onClick={() => { refCertificadoFaixaPretaFile.current.click() }}>
                                                      <label htmlFor="file-input" className="file-label" style={{ boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)', height: '40px', display: 'flex', alignItems: 'center' }}>
                                                        Selecionar
                                                      </label>
                                                    </div></td>

                                                    <td><div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                      <input
                                                        ref={refCertificadoFaixaPretaFile}
                                                        type="file"
                                                        id="file-input1"
                                                        className="file-input"
                                                        onChange={(e) => handleCertificadoFaixaPreta(e)}
                                                      />
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{nomeArquivoCertificadoFaixaPreta}</p>
                                                    </div></td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td>
                                                      {(!filiacao.id && !uploadArquivoCertificadoFaixaPreta) && 'Não Anexado'}
                                                      {(uploadArquivoCertificadoFaixaPreta) && 'Anexado'}
                                                      {(filiacao.statusImagemCertificadoFaixaPreta === 1 && !uploadArquivoCertificadoFaixaPreta) && (
                                                        <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                      )}
                                                      {(filiacao.statusImagemCertificadoFaixaPreta === 2 && !uploadArquivoCertificadoFaixaPreta) && (
                                                        <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                      )}
                                                      {(filiacao.statusImagemCertificadoFaixaPreta === 3 && !uploadArquivoCertificadoFaixaPreta) && (
                                                        <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                      )}
                                                      {(filiacao.statusImagemCertificadoFaixaPreta === 4 && !uploadArquivoCertificadoFaixaPreta) && (
                                                        <span style={{ fontWeight: 'bold', color: 'red' }}>Renovar</span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Observação</td>
                                                    <td>
                                                      {(filiacao.statusImagemCertificadoFaixaPreta === 3) && (
                                                        <div
                                                          className='badge-doc-reprovada'
                                                          style={{ cursor: 'pointer' }}
                                                          onClick={() => mensagemReprovado(filiacao.observacaoCertificadoFaixaPreta)} data-toggle="modal" data-target="#modal"
                                                        >
                                                          <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            )}


                                            {/* {exibirInputComprovante() && (
                                              <table style={{ marginTop: '30px' }}>
                                                <tbody>
                                                  <tr>
                                                    <td style={{ width: '111px' }}>Documento</td>
                                                    <td>Comprovante de Pagamento</td>
                                                  </tr>
                                                  <tr>
                                                    <td><div className="file-input-wrapper" onClick={() => { refComprovanteFile.current.click() }}>
                                                      <label htmlFor="file-input" className="file-label" style={{ boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)', height: '40px', display: 'flex', alignItems: 'center' }}>
                                                        Selecionar
                                                      </label>
                                                    </div></td>

                                                    <td><div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                      <input
                                                        ref={refComprovanteFile}
                                                        type="file"
                                                        id="file-input1"
                                                        className="file-input"
                                                        onChange={(e) => handleFotoComprovante(e)}
                                                      />
                                                      <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                    </div></td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td>
                                                      {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                      {(uploadArquivoComprovante) && 'Anexado'}
                                                      {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                        <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                      )}
                                                      {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                        <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                      )}
                                                      {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                        <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Observação</td>
                                                    <td>
                                                      {pagamentoPendente.status === 3 && (
                                                        <div
                                                          className='badge-doc-reprovada'
                                                          style={{ cursor: 'pointer' }}
                                                          onClick={() => mensagemReprovado(pagamentoPendente.observacaoComprovante)} data-toggle="modal" data-target="#modal"
                                                        >
                                                          <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                        </div>
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            )} */}


                                          </>


                                        )}

                                        {/* Tabela anexar arquivos - Fim */}



                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>


                        </div>
                      </div>
                    )}


                    {/* Dados da Filiação - Fim */}

                    {/* Histórico - Inicio */}

                    {(pagamentosAprovados.length > 0) && (
                      <>

                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>
                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>



                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ margin: '25px', textAlign: 'center' }}>
                              <h3>Histórico</h3>
                            </div>
                            <table style={{ width: "100%", marginTop: 20 }}>
                            </table>
                            {!mediaQuery.matches && (
                              <table style={{ width: "100%" }}>
                                <thead style={{ height: '15px' }}>
                                  <tr style={{ background: '#121212', color: 'white' }}>
                                    <th style={{ height: '15px' }} class="" >Valor </th>
                                    <th style={{ height: '15px' }} class="">Data Aprovado</th>
                                    <th style={{ height: '15px' }} class="">Forma Pagamento</th>
                                    <th style={{ height: '15px' }} class="">Anexo <i className='fa fa-paperclip'></i></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {pagamentosAprovados.sort((a, b) => b.dtAprovado - a.dtAprovado).map(pgto => (
                                    <tr key={pgto.id}>
                                      <td>{pgto.valorTotal ? pgto.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'R$ 0,00'}</td>
                                      <td>{pgto.dtAprovado ? converterData(pgto.dtAprovado) : 'sem anexo'}</td>
                                      <td>{pgto?.tipoPagamento === 'online' ? descricaoFormaPagamento(pgto?.dadosApi?.formaPagamento) : 'Manual'}</td>
                                      <td>
                                      
                                        {pgto?.urlComprovantePgto ? (
                                          <a href={pgto.urlComprovantePgto} target='_blank' style={{ textDecoration: 'underline', color: 'blue' }}>{pgto?.nomeImagemComprovantePgto}</a>
                                        ) : (
                                          <>
                                            {'sem anexo'}
                                          </>)
                                        }

                                      </td>

                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            )}

                            {mediaQuery.matches && (

                              <>
                                {pagamentosAprovados.sort((a, b) => b.dtAprovado - a.dtAprovado).map(pgto => (
                                  <table style={{ marginBottom: '30px' }}>
                                    <tbody>
                                      <tr>
                                        <td>Valor</td>
                                        <td>{pgto.valorTotal ? pgto.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'R$ 0,00'}</td>
                                      </tr>
                                      <tr>
                                        <td>Data Aprovado</td>
                                        <td>{pgto.dtAprovado ? converterData(pgto.dtAprovado) : 'sem anexo'}</td>
                                      </tr>
                                      <tr>
                                        <td>Forma de Pagamento</td>
                                        <td>{pgto?.tipoPagamento === 'online' ? descricaoFormaPagamento(pgto?.dadosApi?.formaPagamento) : 'Manual'}</td>
                                      </tr>
                                      
                                      <tr>
                                        <td>Anexo <i className='fa fa-paperclip'></i></td>
                                        <td>
                                        {pgto?.urlComprovantePgto ? (
                                          <a href={pgto.urlComprovantePgto} target='_blank' style={{ textDecoration: 'underline', color: 'blue' }}>{pgto?.nomeImagemComprovantePgto}</a>
                                        ) : (
                                          <>
                                            {'sem anexo'}
                                          </>)
                                        }
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>

                                ))}
                              </>



                            )}


                          </div>
                        </div>

                      </>
                    )}
                    {/* Histórico - Fim */}

                    {/* Carteirinha - Início */}
                    {/* obs: O acesso às carteirinhas agora será feita através da página /detalheFiliacao */}

                    {/* 
                    {((filiacao.id && filiacao.statusFiliacao == 2) && (federacao?.carteirinha?.carteirinhaFisica == '1' || federacao?.carteirinha?.carteirinhaPdf == '1')) && (
                      <>

                        <div style={{ marginTop: '50px' }}>
                          <div style={{ border: '1px solid rgba(0, 0, 0, .125)', borderRadius: '5px', padding: '15px' }}>


                            <div style={{ margin: '25px 0', textAlign: 'center' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                              <h3>Carteirinha</h3>
                            </div>

                            {federacao?.carteirinha?.carteirinhaFisica == '1' && (
                              <p>{federacao?.carteirinha?.gerarPedidoComAFiliacao == '1' ? '* Clique no  botão ao lado para acompanhar a solicitação da sua carteirinha.' : '* Clique no  botão ao lado para solicitar sua carteirinha.'}<i class="fa fa-hand-o-right" aria-hidden="true"></i><div style={{ margin: '0 10px', padding: '0 5px', background: '#ffc107', cursor: 'pointer', display: 'inline-block', width: 'fitContent', borderRadius: '5px', }} >
                                <a href={`/carteirinha?fl=${filiacao.id}&fd=${idFederacao}`}>
                                  <button style={{ cursor: 'pointer', fontSize: '16px', color: 'black', padding: '8px' }} class="wpcf7-form-control wpcf7-pnchr_submit" >Carteirinha &nbsp;<i className='fa fa-list-alt' aria-hidden="true"></i></button>
                                </a>

                              </div></p>
                            )}

                            {federacao?.carteirinha?.carteirinhaPdf == '1' && (

                              <p>* Clique no  botão ao lado para gerar sua carteirinha em PDF <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                                <div onClick={() => Swal.fire('Em desenvolvimento', 'Recurso em desenvolvimento. Disponível em breve.', 'info')} style={{ margin: '0 10px', padding: '0 5px', background: '#ffc107', cursor: 'pointer', display: 'inline-block', width: 'fitContent', borderRadius: '5px', }} >
                                  <input style={{ cursor: 'pointer', fontSize: '16px', color: 'black' }} type="submit" value={'Carteirinha PDF'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                              </p>

                            )}

                          </div>
                        </div>

                      </>
                    )} */}

                    {/* Carteirinha - Fim */}

                    {/* Termos da Filiação - Início */}
                    {usuarioFiliacao.id && (
                      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px' }}>


                        <div className=' wpb_row row'>

                          <div class="wpb_column vc_column_container vc_col-sm-12">



                            <div class="vc_column-inner">
                              <div class="wpb_wrapper">
                                <div class="wpb_widgetised_column wpb_content_element">
                                  <div class="wpb_wrapper">

                                    <div style={{ margin: '50px 0 25px 0', textAlign: 'center' }} id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                      <h3>Termos da Filiação</h3>
                                    </div>
                                    {federacao?.regrasFiliacao?.tipoFiliacao === 2 && (
                                      <>
                                        <p>* A filiação só será confirmada após a confirmação do pagamento e aprovação da documentação anexada.</p>
                                        <p>* O pagamento deverá ser realizado através de transferência bancária ou Pix e o comprovante deverá ser anexado no ato da inscrição. O comprovante será analisado para que a filiação seja confirmada.</p>
                                      </>
                                    )}
                                    {federacao?.regrasFiliacao?.tipoFiliacao === 1 && (
                                      <p>* A filiação só será confirmada após a aprovação da documentação anexada.</p>
                                    )}
                                    <p>* O documento de identidade escolhido RG/CNH deve ser anexado frente e verso no mesmo arquivo como no modelo ao lado <i class="fa fa-hand-o-right" aria-hidden="true"></i> <img src={RG_Frente_Verso} style={{ display: 'inline-block', height: '50px', marginLeft: '10px' }}></img></p>
                                    <p>* A foto do filiado deve ser em formato "Foto de Documento" como no modelo ao lado <i class="fa fa-hand-o-right" aria-hidden="true"></i> <img src={Foto_Documento} style={{ display: 'inline-block', height: '50px', marginLeft: '10px' }}></img></p>

                                    <p>* Caso alguma documentação seja rejeitada será necessário reanexar novo arquivo para que possa ser validado pela Federação / Agremiação</p>
                                    <p>* Em caso de dúvidas entre em contato com a Federação/Agremiação através dos dados fornecidos nesta página.</p>
                                    <div id='concordo-termos-filiacao' className="checkbox-wrapper">
                                      <input
                                        type="checkbox"
                                        id="checkbox"
                                        className="checkbox-input"
                                        checked={concordoTermo}
                                        onChange={() => setConcordoTermo(!concordoTermo)}
                                      />
                                      <label htmlFor="checkbox" className="checkbox-label">
                                        <span className="checkbox-custom"></span>
                                        Declaro que li e concordo com os termos da filiação.
                                      </label>
                                    </div>

                                    {/* Termos da Filiação - Fim */}


                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                      <div onClick={() => salvarFiliacao()} style={{ marginTop: '30px', background: '#28a745', cursor: 'pointer', width: `${mediaQuery.matches ? '100%' : 'revert-layer'}` }} class={`form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3`}>
                                        <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value={
                                          !filiacao.id ? 'Confirmar Filiação' :
                                            filiacao.statusFiliacao === 4 ?
                                              'Renovar Filiação' : 'Confirmar alterações'} class="wpcf7-form-control wpcf7-pnchr_submit" />
                                      </div>
                                    </div>



                                    {/* {false && (
                                      <div onClick={() => excluirFiliacao()} style={{ margin: '30px 0 0 0px', background: '#dc3545', cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                        <input style={{ cursor: 'pointer', fontSize: '16px' }} type="submit" value='Excluir Filiação' class="wpcf7-form-control wpcf7-pnchr_submit vc_col-sm-3" />
                                      </div>
                                    )} */}



                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    )}

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      {pagamentoPendente.id && (
                        <a href={`/filiacao/pagamento?fd=${idFederacao}&fl=${filiacao.id}`} style={{ marginTop: "20px", background: 'orange', cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3" > Pagamento <i class="fa fa-arrow-right"></i></a>
                      )}
                      <div style={{ marginTop: "20px", background: "#17a2b8", cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3" onClick={() => history.goBack()} ><i class="fa fa-arrow-left"></i> Voltar</div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>

        </main>
      </div>
      <Footer />
      {modalShow && (
        <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={() => { }} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores}
          setDadosProfessor={setProfessor}
        />
      )}
    </div>
  );
}
