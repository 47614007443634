

import {
    useState, useEffect, useRef
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { geraPreferencia, geraPreferenciaMP } from '../service/MercadoPago'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'


export default function PagamentoFiliacao() {
    const ref = useRef(null);


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const idFederacao = '8eLwvNFtMWCj12W1OVn3';
    // const idFiliacao = 'ura4AjMHf6WAqsJ7KoF4';
    const idFiliacao = queryParams.get('fl');
    const idReferencia = queryParams.get('idref');
    const idFederacao = queryParams.get('fd');

    const id = 'BbgecspDgg1TkN8qTmJs';
    const idInscricao = 'HgWtMKkdt17jKxCZyTUW';
    const pathname = window.location.pathname;
    const origemPagamento = window.location.pathname.split('/')[1];

    const [inscricao, setIncricao] = useState({})
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [url, setUrl] = useState('')
    const [pagamentoManual, setPagamentoManual] = useState(false);

    const [fotoComprovante, setFotoComprovante] = useState('Nenhum arquivo selecionado');
    const [arquivoComprovante, setArquivoComprovante] = useState('');
    const [uploadArquivoComprovante, setUploadArquivoComprovante] = useState('');
    const refComprovanteFile = useRef(null);
    const [pagamentoPendente, setPagamentoPendente] = useState({})

    const [federacao, setFederacao] = useState({})
    const [filiacao, setFiliacao] = useState({});

    const [progresso, setProgresso] = useState('');
    const [loadingImage, setLoadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(loading);
    useEffect(() => { loadingRef.current = loading; }, [loading]);



    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        federacoes: firebase.firestore().collection('federacoes'),
        filiacoes: firebase.firestore().collection('federacoes').doc(idFederacao).collection('filiacoes'),
        mail: firebase.firestore().collection('mail'),
        auth: firebase.auth()
    })




    const mediaQuery = window.matchMedia('(max-width: 450px)');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))

            if (user || idUser) {

                // mensagemLoading('Carregando dados do pagamento..')
                // setLoading(true)

                servicos.usuarios.doc(user?.uid ? user.uid : idUser).get()
                    .then((doc) => {

                        if (!doc.exists) {
                            window.location = '/';
                        }

                        setUsuario(doc.data());

                    })

                carregarFederacao();
                carregarFiliacao();
                carregarPagamentos();



            } else {
                window.location = '/'
            }

        })
    }, [])


    const carregarFederacao = () => {
        servicos.federacoes.doc(idFederacao).get().then(snap => {

            if (!snap.exists) {
                window.location = '/';
            }
            const _federacao = snap.data();
            setFederacao(_federacao);

        })
    }

    const carregarFiliacao = () => {
        return servicos.filiacoes.doc(idFiliacao).get().then(snap => {
            if (!snap.exists) {
                window.location = '/';
            }
            const _filiacao = snap.data();

            const documentos = [_filiacao?.statusImagemCertificadoFaixaPreta, _filiacao?.statusImagemRg, _filiacao?.statusImagemFoto];
            const reprovado = 3;

            if (documentos.includes(reprovado)) {
                mensagemAlerta('Voçe possui pendências com sua documentação anexada. Verifique suas pendências antes de prosseguir com o pagamento.').then(() => {
                    window.location.href = `/filiacao/${idFederacao}?fl=${idFiliacao}`;
                });
            }

            setFiliacao(_filiacao);
        })
    }

    function carregarPagamentos() {

        servicos.filiacoes.doc(idFiliacao).collection('pagamentos').get().then(snap => {

            if (snap.empty) {
                window.location = '/';
            }

            const pagamentos = snap.docs.map(pgto => {
                return {
                    id: pgto.id,
                    ...pgto.data()
                }
            });

            const existePagamentoPendente = pagamentos.some(pgto => pgto.status !== 2);
            const existePagamentoAprovado = pagamentos.some(pgto => pgto.status == 2);

            if (existePagamentoPendente) {
                const _pagamentoPendente = pagamentos.find(pgto => pgto.status !== 2)
                setPagamentoPendente(_pagamentoPendente);
                setFotoComprovante(_pagamentoPendente?.nomeImagemComprovantePgto);
            } else {
                window.location = '/';
            }

        })
    }



    async function pagamento() {
        // window.location = window.location.href.replace('/pagamento/' + id, '/pagamentoManual/' + id)
        window.location.href = `/pagamentoManual?e=${id}&i=${idInscricao}`


    }

    async function mensagemConfirmacao(titulo, texto, icon) {

        const result = await Swal.fire({
            title: titulo,
            text: texto,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        });
        if (result.isConfirmed) {
            return true;
        } else if (result.isDismissed || result.isDenied) {
            return false;
        }
    }

    function mensagemAlerta(msg) {
        return Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    function mensagemReprovado(msg) {
        Swal.fire('Documento Reprovado', msg, 'warning')
    }

    function mensagemErro(msg) {
        return Swal.fire('Erro', msg, 'error')
    }


    const handleFotoComprovante = (event) => {
        event.stopPropagation()
        setArquivoComprovante(event.target.files[0])
        if (uploadArquivoComprovante) {
            URL.revokeObjectURL(uploadArquivoComprovante)
        }
        setUploadArquivoComprovante(URL.createObjectURL(event.target.files[0]) ? URL.createObjectURL(event.target.files[0]) : '')
        const file = event.target.files[0];
        console.log('file: ', file);
        setFotoComprovante(file ? file.name : 'Nenhum arquivo selecionado');

    };


    async function uploadArquivo(idFiliacao, nomeArquivo, arquivo, idPagamento) {

        return new Promise((resolve, reject) => {

            let caminho = (nomeArquivo !== 'comprovante') ?
                `federacoes/${idFederacao}/filiacoes/${idFiliacao}/${nomeArquivo}` :
                `federacoes/${idFederacao}/filiacoes/${idFiliacao}/pagamentos/${idPagamento}`;

            var uploadTask = firebase.storage().ref().child(caminho).put(arquivo, { contentType: arquivo.type });

            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgresso(parseInt(progress) + '%')
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('uploadImagem is paused');
                        setLoadingImage(false)
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log(`Salvando ${nomeArquivo}`);
                        break;
                    default:
                        console.log('Passou')
                }
            }, (error) => {
                console.error(`Error during upload: ${error.message}`);
                reject(error); // Ensure promise is rejected on error
            }, async () => {


                try {

                    const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();

                    let dados = {};

                    if (nomeArquivo === 'comprovante') {

                        dados = {
                            urlComprovantePgto: downloadURL,
                            nomeImagemComprovantePgto: arquivo.name,
                            dtAnexo: firebase.firestore.Timestamp.fromDate(new Date()),
                            status: 1
                        }

                        if (pagamentoPendente.status === 3) {
                            dados = { ...dados, observacaoComprovante: '' }
                        }


                    }

                    resolve(dados);
                } catch (error) {

                    console.error(`Unexpected error in uploadArquivo: ${error.message}`);
                    reject(error);
                }

            });

        });

    }

    async function salvarPagamentoManual() {

        if (!uploadArquivoComprovante) {
            mensagemAlerta('Por favor, anexe um comprovante de pagamento para poder prosseguir.');
            return;
        }

        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB limit

        if (arquivoComprovante.size > MAX_FILE_SIZE) {
            mensagemAlerta('O Comprovante anexado excede o tamanho máximo permitido (5MB)');
            return false;
        }

        const titulo = 'Confirmar envio de comprovante';
        const texto = "Confirma envio de comprovante de pagamento?"
        if (! await mensagemConfirmacao(titulo, texto, 'question')) {
            return;
        }

        mensagemLoading();

        let dadosPgto = {
            tipoPagamento: 'manual',
            id: pagamentoPendente.id
        }

        let dadosUploadComprovantePgto;

        while (true) {
            try {
                dadosUploadComprovantePgto = await uploadArquivo(idFiliacao, 'comprovante', arquivoComprovante, pagamentoPendente.id);
                break;
            } catch (error) {
                console.log('Não foi possível realizar o upload!');
                console.log('Erro: ', error);
            }
        }

        if (!dadosUploadComprovantePgto) {
            mensagemAlerta('Não foi possível salvar seus dados de pagamento. Verifique sua conexão de internet e tente novamente!');
            return;
        }

        dadosPgto = {
            ...dadosPgto,
            ...dadosUploadComprovantePgto
        };

        await servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).collection('pagamentos').doc(pagamentoPendente.id).update(dadosPgto).then(() => {
            return servicos.federacoes.doc(idFederacao).collection('filiacoes').doc(idFiliacao).update({ statusFiliacao: 1 });
        }).then(() => {
            return enviarEmail();
        }).catch(err => {
            console.log('erro ao salvar pagamento: ', err);
            mensagemErro('Não foi possível concluir o pedido de pagamento. Verifique sua conexão de internet e tente novamente.').then(() => {
                window.location.reload();
            })

        });

        let textoConcluido = 'Sua solicitação para filiação foi gerada e a Federação irá avaliar sua documentação em breve. Para acompanhar o andamento basta acessar novamente a página da federação.'

        Swal.fire('Concluído', textoConcluido, 'success').then(() => {
            window.location.href = `/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`;
        })
    }

    async function pagamentoOnline() {

        if (pagamentoPendente.urlComprovantePgto) {
            const titulo = 'prosseguir com pagamento online?'
            const texto = "Atenção! Seu pagamento já possui um comprovante anexado e está aguardando aprovação da Federação. Se prosseguir com pagamento online, sua documetação anexada será desconsiderada. Deseja realmente continuar com pagamento online?"

            if (! await mensagemConfirmacao(titulo, texto, 'danger')) {
                return;
            }

        }

        const referenciaExterna = `flc-${idFederacao}-${idFiliacao}-${pagamentoPendente.id}`;

        const dados = {
            items: [
                {
                    id: pagamentoPendente.id,
                    title: `Filiação ${federacao?.nome}`,
                    description: `Torne-se um filiado da ${federacao?.nome}`,
                    picture_url: federacao?.urlImagemFederacao,
                    category_id: "affiliation",
                    quantity: 1,
                    currency_id: "BRL",
                    unit_price: parseFloat(pagamentoPendente.valorTotal),
                }
            ],
            payment_methods: {
                excluded_payment_types: pagamentosExcluidos(),
                installments: 1,
                default_payment_method_id: null
            },
            payer: {
                name: filiacao.nomeFiliado,
                email: filiacao.emailFiliado,
                identification: {
                    type: 'CPF',
                    number: filiacao.cpfFiliado,
                },
            },
            metadata: {
                internal_ref: 'flc',
                ref_chave_pgto: federacao.chavePagamento,
                id_federacao: idFederacao,
                id_filiacao: idFiliacao,
                id_pgto: pagamentoPendente.id,
            },
            auto_return: "all",
            external_reference: referenciaExterna,
            back_urls: {
                success: `${window.location.origin}/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`,
                pending: `${window.location.origin}/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`,
                failure: `${window.location.origin}/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`,

            },
            notification_url: urlNotificacao(),
            statement_descriptor: "PRO COMPETIDOR",

        }

        servicos.filiacoes.doc(filiacao.id).collection('pagamentos').doc(pagamentoPendente.id).update({
            tipoPagamento: 'online',
            urlComprovantePgto: null,
            status: 0
        }).then(() => {

            if (filiacao.statusFiliacao === 1) {
                return servicos.filiacoes.doc(filiacao.id).update({
                    statusFiliacao: 0
                });
            }

            return new Promise((resolve) => resolve());

        }).then(() => {
            return geraPreferenciaMP(dados, federacao.chavePagamento);
        }).then(linkMercadoPago => {

            if (!linkMercadoPago) {
                mensagemErro('Serviço indisponível no momento. Tente novamente mais tarde.')
            }else{
                window.open(linkMercadoPago, "_blank");
            }

        }).catch(err => {
            mensagemErro('Serviço indisponível no momento. Tente novamente mais tarde.')
            console.log('Erro ao criar preferencia: ', err);
        })


    }

    function pagamentosExcluidos() {

        let formasPagamento = federacao.formasPagamento ? federacao.formasPagamento : []
        let _pagamentosExcluidos = [
            { id: "digital_wallet" },
            { id: "digital_currency" },
            { id: "debit_card" },
            { id: "bank_transfer" },
            { id: "credit_card" },
            { id: "ticket" }
        ]

        if (formasPagamento.some(fp => fp === 'credito')) {
            _pagamentosExcluidos = _pagamentosExcluidos.filter(pe => pe.id !== 'credit_card');
        }
        if (formasPagamento.some(fp => fp === 'debito')) {
            _pagamentosExcluidos = _pagamentosExcluidos.filter(pe => pe.id !== 'debit_card');
        }
        if (formasPagamento.some(fp => fp === 'pix')) {
            _pagamentosExcluidos = _pagamentosExcluidos.filter(pe => pe.id !== 'bank_transfer');
        }
        if (formasPagamento.some(fp => fp === 'boleto')) {
            _pagamentosExcluidos = _pagamentosExcluidos.filter(pe => pe.id !== 'ticket');
        }

        return _pagamentosExcluidos;

    }

    function urlNotificacao() {

        let url = null;

        const baseUrlTeste = 'http://127.0.0.1:5001/demo-teste/us-central1/app';
        const baseUrl = "https://us-central1-procompetidor-7867b.cloudfunctions.net/app";

        switch (federacao?.chavePagamento) {

            case 'dyqpNpZC5XWxoWLjZ4P8': url = `${baseUrl}/mercadopago/webhook/procompetidor?source_news=webhook`; break;

            case 'c3PxVbc1jtcsFfGeKrLV': url = `${baseUrl}/mercadopago/webhook/fjjm?source_news=webhook`; break;

            case 'HSKkSrtbHi6I078dIzeW': url = `${baseUrlTeste}/mercadopago/webhook/procompetidor?source_news=webhook`; break;
            
            case 'JTcJvboNGHf4z4HPGotu': url = `${baseUrlTeste}/mercadopago/webhook/fjjm?source_news=webhook`; break;
        }

        return url;
    }


    function exibirPagamentoManual() {
        if (!federacao?.pagamentoOnline || !federacao?.pagamentoOnline == '0' || (federacao?.pagamentoOnline === '1' && federacao?.formaPagamento.some(fp => fp === 'manual'))) {
            return true;
        }

        return false;
    }

    function exibirPagamentoOnline() {

        const _formasPagamento = federacao?.formasPagamento ? federacao?.formasPagamento.filter(fp => fp !== 'manual') : [];

        if (federacao?.pagamentoOnline == '1' && _formasPagamento.length > 0) {
            return true;
        }

        return false;
    }

    async function enviarEmail() {
        const destinatario = federacao?.email;
        const enviarNotificao = (destinatario && federacao?.receberNotificacaoEmail)

        if (enviarNotificao) {
            const emailNotificacao = {
                to: destinatario,
                from: 'Procompetidor',
                message: {
                    subject: 'Nova solicitação de filiação/renovação',
                    html: '<html>' +
                        '<body>' +
                        '<p>Atenção! Você possui uma nova solicitação de filiação/renovação para aprovar. Verifique sua lista de filiados. </p>' +
                        '<p>solcitante: <b>' + `${filiacao?.nomeFiliado}` + ' </b></p>' +
                        '<br/> <br/>' +
                        '<h5>Equipe Procompetidor</h5>' +
                        '</body>' +
                        '</html>'
                }
            }

            servicos.mail.add(emailNotificacao)
            console.log('email enviado');

        }

    }


    return (
        <div className="body-wrapper blank-loder">
            <input ref={ref} type="hidden" id="deviceId"></input>
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Formas </h1>
                            <h1>de Pagamento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Escolha uma forma de pagamento para concluir sua {origemPagamento === 'filiacao' ? "filiação." : "solicitação de carteirinha."}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12" style={{ display: "flex", justifyContent: 'center' }}>
                            <div class="wpb_column vc_col-sm-8">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_widgetised_column wpb_content_element">
                                            <div class="wpb_wrapper">
                                                <div role="form" class="row " >
                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>

                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                            <thead>
                                                                <tr >
                                                                    <th class="schedule-table__day">Extrato</th>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                        <table style={{ width: "100%" }} className="3">
                                                            <tbody>

                                                                <tr>
                                                                    <td>Filiação</td>
                                                                    <td>{pagamentoPendente?.valorFiliacao ? parseFloat(pagamentoPendente?.valorFiliacao).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : 'R$ 0,00'}</td>
                                                                </tr>
                                                                {pagamentoPendente?.valorCarteirinha && (
                                                                    <tr>
                                                                        <td>Carteirinha Física</td>
                                                                        <td>{parseFloat(pagamentoPendente?.valorCarteirinha).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                    </tr>
                                                                )}
                                                                {pagamentoPendente?.valorCarteirinhaDigital && (
                                                                    <tr>
                                                                        <td>Carteirinha Digital</td>
                                                                        <td>{parseFloat(pagamentoPendente?.valorCarteirinhaDigital).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                    </tr>
                                                                )}

                                                                {(pagamentoPendente?.valorTaxaEnvio) && (
                                                                    <tr>
                                                                        <td>Taxa de Envio</td>
                                                                        <td>{parseFloat(federacao?.carteirinha?.valorTaxaEnvio).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                                    </tr>
                                                                )}

                                                                <tr>
                                                                    <td><b>Total a Pagar</b></td>
                                                                    <td><b>{parseFloat(pagamentoPendente?.valorTotal).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {(exibirPagamentoOnline()) && (
                                                        <div onClick={() => pagamentoOnline()} style={{ width: "100%", marginTop: 20, cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento Online</div>
                                                    )}

                                                    {(exibirPagamentoManual()) && (
                                                        <div onClick={() => setPagamentoManual(!pagamentoManual)} style={{ width: "100%", marginTop: 20, cursor: 'pointer' }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento direto ao Administrador</div>
                                                    )}

                                                    {pagamentoManual && (
                                                        <>

                                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '50px', paddingTop: '30px', border: '1px solid rgba(0, 0, 0, .125)', maxWidth: '900px' }}>
                                                                {/* Dados Bancários - Início */}
                                                                {true && (
                                                                    <div class="wpb_column vc_column_container vc_col-sm-12">

                                                                        <div class="vc_column-inner ">
                                                                            <div class="wpb_wrapper">
                                                                                <div class="wpb_widgetised_column wpb_content_element">
                                                                                    <div class="wpb_wrapper">

                                                                                        <div role="form" class="row " >

                                                                                            <h4 style={{ textAlign: 'center', display: 'block' }}>*Dados Bancários</h4>

                                                                                            <p class="contact-form-name vc_col-sm-8">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Nome Favorecido</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.favorecido}
                                                                                                        disabled
                                                                                                        style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-4">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>CPF/CNPJ</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.cpfCnpj}
                                                                                                        disabled
                                                                                                        style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-4">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Agência</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.agencia}
                                                                                                        disabled style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-4">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Conta</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.conta}
                                                                                                        disabled style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-4">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Banco</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.banco}
                                                                                                        disabled style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-3">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Tipo Chave Pix</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.tipoChavePix}
                                                                                                        disabled style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>
                                                                                            <p class="contact-form-name vc_col-sm-9">
                                                                                                <span class="wpcf7-form-control-wrap your-name">
                                                                                                    <h6>Chave Pix</h6>
                                                                                                    <input
                                                                                                        type="text" name="your-name" size="40"
                                                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                                        aria-required="true" aria-invalid="false"
                                                                                                        value={federacao?.dadosBancarios?.chavePix}
                                                                                                        disabled style={{ background: '#F5F5F5' }} />
                                                                                                </span>
                                                                                            </p>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {/* Dados Bancários - Fim */}
                                                            </div>
                                                            <p style={{ marginTop: '10px' }}>* Realize seu pagamento via <b>Pix</b> ou <b>Transferência Bancária</b> e anexe seu comprovante no campo abaixo.</p>
                                                            <table style={{ width: "100%", marginTop: 20 }}>
                                                                <thead>
                                                                    <tr >
                                                                        <th class="schedule-table__day">
                                                                            Anexe abaixo o comprovante de pagamento da sua {origemPagamento}.</th>
                                                                    </tr>
                                                                </thead>
                                                            </table>

                                                            {!mediaQuery.matches && (
                                                                <table style={{ width: "100%" }} className="3">
                                                                    <thead style={{ height: '15px' }}>
                                                                        <tr style={{ background: '#121212', color: 'white' }}>
                                                                            <th className="" style={{ width: '130px' }} >Documento: </th>
                                                                            <th className="">Anexar: </th>
                                                                            <th className="" style={{ width: '130px' }}>Status: </th>
                                                                            <th className="">Observação:</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {true && (
                                                                            // Obs: A solicitação de pagamento só deve ocorrer se o tipoFiliacao for pago (2)
                                                                            <tr>
                                                                                <td>Comprovante de Pagamento</td>
                                                                                <td onClick={() => { refComprovanteFile.current.click() }}>
                                                                                    <div className="file-input-wrapper">
                                                                                        <input
                                                                                            ref={refComprovanteFile}
                                                                                            type="file"
                                                                                            id="file-input2"
                                                                                            className="file-input"
                                                                                            onChange={(e) => handleFotoComprovante(e)}
                                                                                        />
                                                                                        <label htmlFor="file-input" className="file-label">
                                                                                            Selecione um arquivo
                                                                                        </label>
                                                                                        <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                                                    </div>
                                                                                </td>
                                                                                <td>


                                                                                    {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                                                    {(pagamentoPendente.status === 0 && !uploadArquivoComprovante) && 'Não Anexado'}
                                                                                    {(uploadArquivoComprovante) && 'Anexado'}
                                                                                    {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                                                        <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                                                    )}
                                                                                    {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                                                        <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                                                    )}
                                                                                    {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                                                        <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {pagamentoPendente.status === 3 && (
                                                                                        <div
                                                                                            className='badge-doc-reprovada'
                                                                                            style={{ cursor: 'pointer' }}
                                                                                            onClick={() => mensagemReprovado(pagamentoPendente.observacaoComprovante)} data-toggle="modal" data-target="#modal"
                                                                                        >
                                                                                            <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                            )}

                                                            {mediaQuery.matches && (

                                                                <>
                                                                    {true && (
                                                                        <table style={{ marginTop: '30px' }}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ width: '111px' }}>Documento</td>
                                                                                    <td>Comprovante de Pagamento</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td><div className="file-input-wrapper" onClick={() => { refComprovanteFile.current.click() }}>
                                                                                        <label htmlFor="file-input" className="file-label" style={{ boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.3)', height: '40px', display: 'flex', alignItems: 'center' }}>
                                                                                            Selecionar
                                                                                        </label>
                                                                                    </div></td>

                                                                                    <td><div className="file-input-wrapper" style={{ flexDirection: mediaQuery.matches ? 'column' : 'row' }}>
                                                                                        <input
                                                                                            ref={refComprovanteFile}
                                                                                            type="file"
                                                                                            id="file-input1"
                                                                                            className="file-input"
                                                                                            onChange={(e) => handleFotoComprovante(e)}
                                                                                        />
                                                                                        <p style={{ margin: '2px 10px' }} className="file-name">{fotoComprovante}</p>
                                                                                    </div></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Status</td>
                                                                                    <td>
                                                                                        {(!pagamentoPendente.id && !uploadArquivoComprovante) && 'Não Anexado'}
                                                                                        {(uploadArquivoComprovante) && 'Anexado'}
                                                                                        {(pagamentoPendente.status === 1 && !uploadArquivoComprovante) && (
                                                                                            <span style={{ fontWeight: 'bold', color: 'orange' }}>Aguardando Aprovação</span>
                                                                                        )}
                                                                                        {(pagamentoPendente.status === 2 && !uploadArquivoComprovante) && (
                                                                                            <span style={{ fontWeight: 'bold', color: 'green' }}>Aprovado</span>
                                                                                        )}
                                                                                        {(pagamentoPendente.status === 3 && !uploadArquivoComprovante) && (
                                                                                            <span style={{ fontWeight: 'bold', color: 'red' }}>Reprovado</span>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Observação</td>
                                                                                    <td>
                                                                                        {pagamentoPendente.status === 3 && (
                                                                                            <div
                                                                                                className='badge-doc-reprovada'
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                onClick={() => mensagemReprovado(pagamentoPendente.observacaoComprovante)} data-toggle="modal" data-target="#modal"
                                                                                            >
                                                                                                <span className="badge-doc-reprovada badge-danger"><i className='fa fa-commenting'></i></span>
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    )}
                                                                </>



                                                            )}

                                                            <div className='vc_col-sm-12' style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <div onClick={() => salvarPagamentoManual()} style={{ marginTop: 20, cursor: 'pointer', background: '#218838' }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-5" >Enviar Comprovante</div>
                                                            </div>


                                                        </>
                                                    )}


                                                    <div className='vc_col-sm-12' style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <a href={`/detalheFiliacao?f=${idFederacao}&fl=${idFiliacao}`} style={{ marginTop: "20px", background: "#17a2b8" }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-5" ><i class="fa fa-arrow-left"></i> Voltar</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div >
    );
}

